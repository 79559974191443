import { ProfileUpdateImageResponseResult } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { MedMeAPI } from '@gbooking/ts-sdk';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

export const useUpdatePhoto = (): {
  updatePhoto: UseMutateAsyncFunction<
    ProfileUpdateImageResponseResult,
    unknown,
    string,
    unknown
  >;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationKey: ['photos'],
    mutationFn: (fileToken: string) =>
      MedMeAPI.profile.updateImage({ fileToken }),
    onSuccess: () => {
      void queryClient.invalidateQueries(['photos']);
    },
  });
  return { updatePhoto: mutateAsync };
};
