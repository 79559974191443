import { useQuery } from '@tanstack/react-query';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { defaultTheme } from 'utils';
import {
  BusinessGetNetworkDataRequestParams,
  BusinessGetNetworkDataResponseResult,
} from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { setAnyBusinessID } from 'utils/anyBusinessID';

export const useGetNetwork = (
  networkID = '',
  contractID?: string,
  contractExtraId?: string,
): {
  error: unknown;
  data: Partial<BusinessGetNetworkDataResponseResult>;
  isFetching: boolean;
  isLoading: boolean;
} => {
  const params: BusinessGetNetworkDataRequestParams = {
    networkID: networkID,
  };
  if (contractExtraId) {
    params.contract = {
      extraId: contractExtraId,
    };
  } else if (contractID) {
    params.contract = {
      id: contractID,
    };
  }

  const { error, data: networkData, isFetching, isLoading } = useQuery({
    queryKey: ['network', networkID],
    queryFn: () => MedMeAPI.business.getNetworkData(params),
    refetchOnWindowFocus: false,
    staleTime: 1000,
    retry: 0,
    enabled: !!networkID,
  });

  const clientCabinetTranslates = networkData?.result?.businessConfiguration
    ?.newWidgetTheme?.clientCabinetTranslates
    ? JSON.stringify(
        networkData?.result?.businessConfiguration?.newWidgetTheme
          ?.clientCabinetTranslates,
      )
    : '{}';
  const newWidgetTheme = Object.assign(defaultTheme, {
    clientCabinetTranslates,
  });
  const result: Partial<BusinessGetNetworkDataResponseResult> = {
    networkName: networkData?.result?.networkName ?? '',
    businessConfiguration: {
      ...networkData?.result?.businessConfiguration,
      newWidgetTheme: {
        ...newWidgetTheme,
        ...networkData?.result?.businessConfiguration.newWidgetTheme,
      },
    },
    integrationData: {
      ...networkData?.result?.integrationData,
    },
  };

  const anyBusinessID = networkData?.result?.businesses?.[0]?.businessID;
  if (anyBusinessID) {
    setAnyBusinessID(anyBusinessID);
  }

  return { isLoading, error, data: result, isFetching }
}
