import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientContext } from 'utils';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // Пропсы, которые могут быть переданы в wrapper
  className?: string;
  style?: React.CSSProperties;
}

const InputContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const FileInputWrapper = styled('div')((props: { theme: ITheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 20px',
  width: 'fit-content',
  height: 'fit-content',
  borderRadius: '10px',
  cursor: 'pointer',
  backgroundColor: props.theme.passiveColor,
  transition: 'background-color 0.3s ease',
}));

const HiddenFileInput = styled('input')({
  display: 'none',
});

const FileName = styled('span')((props: { theme: ITheme }) => ({
  marginLeft: '10px',
  fontSize: '14px',
  color: props.theme.textColor,
}));

export const FileInput: React.FC<FileInputProps> = ({ ...props }) => {
  const {t} = useTranslation();
  const {theme} = useContext(ClientContext);
  const [fileName, setFileName] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
    }
  };

  return (
    <InputContainer>
      <FileInputWrapper theme={theme}>
        <label>
          <HiddenFileInput 
            {...props} 
            type="file" 
            onChange={(e) => {
              handleFileChange(e);
              if (props.onChange) props.onChange(e);  // Вызов переданного onChange, если есть
            }} 
          />
          <span>{t('components.fileInput.chooseFile')}</span>
        </label>
        {fileName && <FileName theme={theme}>{fileName}</FileName>}
      </FileInputWrapper>
    </InputContainer>
  );
};
