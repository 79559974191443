import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';
import React, { useState, FocusEvent, forwardRef, useContext, InputHTMLAttributes } from 'react';
import { ClientContext } from 'utils';
import { ReactComponent as IconAlert } from '../../assets/icon-alert.svg';
import { Stack } from './Stack';
import { useTranslation } from 'react-i18next';

const InputContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const StyledInput = styled('input')<{ hasError: boolean }>(({ hasError }) => ({
  padding: '10px',
  border: '1px solid',
  borderColor: hasError ? '#dc3545' : '#ccc',
  borderRadius: '4px',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box',
  '&:focus': {
    outline: 'none',
    borderColor: hasError ? '#dc3545' : '#007bff',
    boxShadow: hasError ? '0 0 4px rgba(220, 53, 69, 0.5)' : '0 0 4px rgba(0, 123, 255, 0.5)',
  },
}));

const Tooltip = styled(Stack)((props: {theme: ITheme}) => ({
  alignItems: 'center',
  position: 'absolute',
  top: '100%',
  left: '10%',
  backgroundColor: props.theme.backgroundColor,
  color: props.theme.textColor,
  padding: '5px 10px',
  borderRadius: '4px',
  fontSize: 16,
  marginTop: '4px',
  whiteSpace: 'nowrap',
  border: '1px solid',
  borderColor: props.theme.textColor,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  zIndex: 10,
}));

export const InputWithTooltip = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  ({ ...props }, ref) => {
    const {t} = useTranslation();
    const {theme} = useContext(ClientContext);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };

    const handleInvalid = (e: React.InvalidEvent<HTMLInputElement>) => {
      e.preventDefault();
      setShowTooltip(true);
    };

    const handleInput = () => {
      setShowTooltip(false);
    };

    return (
      <InputContainer>
        <StyledInput
          ref={ref}
          hasError={showTooltip}
          onBlur={handleBlur}
          onInvalid={handleInvalid}
          onInput={handleInput}
          onFocus={handleInput}
          {...props}
        />
        {showTooltip && 
        <Tooltip theme={theme} direction={'row'} spacing={1}>
          <IconAlert width={24} height={24} fill={'black'}/>
          {t('components.inputWithTooltip.fillOutThisField')}
        </Tooltip>}
      </InputContainer>
    );
  },
);
