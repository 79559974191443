import React, { useContext, useEffect, useState } from 'react';
import Login from 'pages/login';
import SetNewPassword from 'pages/setNewPassword';
import Pages from 'pages';
import ProfileEditScreen from 'pages/profileEditScreen';
import { getDisablePasswordLogin } from 'utils/disablePasswordLogin';
import { ClientContext, urlManager } from 'utils';
import { CircleSpinner } from 'components/CircleSpinner';
import { Loading, PageContainer } from 'components';
import { contentCSS } from 'styles';
import { useAuth } from 'components';

export const IsLoggedIn: React.FC = () => {
  const { network, clientInfo, clientInfoPending, user, token, businessInfo, theme } = useContext(ClientContext);
  const [isSkipProfileScreen, setIsSkipProfileScreen] = useState<boolean>(true);
  const [disablePasswordLogin, setDisablePasswordLogin] = useState<boolean>(
    true,
  );
  const { userData } = useAuth();
  const { name, surname } = clientInfo;

  useEffect(() => {
    setIsSkipProfileScreen(!!name && !!surname);
  }, [name, surname]);

  useEffect(() => {
    setDisablePasswordLogin(getDisablePasswordLogin(urlManager.getBusinessId(), urlManager.getNetworkId(), businessInfo, network));
  }, [businessInfo, network]);

  if(!businessInfo) {
    return <PageContainer layoutClassName="login" contentCSS={contentCSS}>
      <Loading theme={theme} />
    </PageContainer>;
  }

  if (!userData || !(user && token)) {
    return <Login />;
  }

  const { askNewPass, showProfileScreen } = userData;

  const renderComponent = () => {
    if(user && token && !clientInfoPending) {
      if (askNewPass && !disablePasswordLogin) {
        return <SetNewPassword />;
      } else if (!isSkipProfileScreen && showProfileScreen) {
        return <ProfileEditScreen />;
      } else if (!askNewPass || disablePasswordLogin) {
        return <Pages />;
      }
    }
    return () => <CircleSpinner />;
  };

  return <>{renderComponent()}</>;
};
