import { Appointment, ExtraField } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import React, { useContext, useState } from 'react';
import { ClientContext } from 'utils';
import { ExtraFieldFileInput } from './ExtraFieldFileInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from 'shared/ui/Stack';
import styled from '@emotion/styled';
import { breakpointLarge, unit } from 'styles';
import { useTranslation } from 'react-i18next';
import { ITheme } from 'data-layer/types';
import { CircleSpinner } from 'components/CircleSpinner';
import { toast } from 'react-toastify';
import {
  AvailableExtraField,
  ExtraFieldsFormType,
  transformAvailableExtraFieldToExtraField,
} from './types';

type AppointmentExtraFieldsFormProps = {
  availableExtraFields: AvailableExtraField[];
  onSubmitExtraFields: (
    extraFields: ExtraField[]
  ) => Promise<Appointment>;
};

export const AppointmentExtraFieldsForm: React.FC<AppointmentExtraFieldsFormProps> = ({
  availableExtraFields,
  onSubmitExtraFields,
}: AppointmentExtraFieldsFormProps) => {
  const { theme } = useContext(ClientContext);
  const { t } = useTranslation();
  const { register, handleSubmit, reset, setValue, watch } = useForm<
    ExtraFieldsFormType
  >({
    defaultValues: {
      extraFields: [...availableExtraFields],
    },
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ExtraFieldsFormType> = (formData) => {
    setIsSaving(true);
    const submitExtraFields = onSubmitExtraFields(
      formData.extraFields.map((extraField) =>
        transformAvailableExtraFieldToExtraField(extraField)
      )
    ).finally(() => setIsSaving(false));

    void toast.promise(submitExtraFields, {
      success: t('screens.appointments.dataLoadedSuccess'),
    });
  };

  const thereIsAvailableForEditing = availableExtraFields.find(
    (field) => field.availableForEditing
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ marginTop: unit * 2 }}>
        <Stack>
          {availableExtraFields.map((extraField, index) => {
            return (
              ((!extraField.availableForEditing && extraField?.value) ||
                extraField.availableForEditing) && (
                <Stack
                  key={`extraField-${extraField.id}`}
                  sx={{
                    [`@media screen and (max-width: ${breakpointLarge}px)`]: {
                      alignItems: 'center',
                    },
                  }}
                >
                  <p>
                    <b>{extraField.name}</b>
                  </p>
                  {/* TODO: change it to map(type -> component) when there are a lot of conditions */}
                  {extraField.fieldType === 'FILE' ? (
                    <ExtraFieldFileInput
                      availableExtraField={extraField}
                      register={register(`extraFields.${index}` as const)}
                      setExtraField={(file) =>
                        setValue(`extraFields.${index}`, file)
                      }
                      watchExtraField={() => watch(`extraFields.${index}`)}
                    />
                  ) : (
                    <Text>{extraField.value}</Text>
                  )}
                </Stack>
              )
            );
          })}
        </Stack>
        {thereIsAvailableForEditing && (
          <Stack direction="row" spacing={4}>
            <ButtonSaveStyle theme={theme} type="submit" disabled={isSaving}>
              {isSaving && <CircleSpinner theme={theme} />}
              <b>{t('components.confirmation.save')}</b>
            </ButtonSaveStyle>
            <ButtonCancelStyle
              theme={theme}
              type="button"
              onClick={() => reset()}
              disabled={isSaving}
            >
              <b>{t('components.confirmation.cancel')}</b>
            </ButtonCancelStyle>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

const Text = styled('p')({
  margin: 0,
});

const ButtonSaveStyle = styled('button')((props: { theme: ITheme }) => ({
  display: 'flex',
  gap: unit * 2,
  border: 'none',
  padding: `${unit}px ${unit * 3}px`,
  borderRadius: unit,
  backgroundColor: props.theme.mainColor,
  color: props.theme.backgroundColor,
  fontSize: '16px',
  cursor: 'pointer',
}));

const ButtonCancelStyle = styled('button')((props: { theme: ITheme }) => ({
  border: 'none',
  background: 'transparent',
  color: props.theme.textColor,
  fontSize: '16px',
  cursor: 'pointer',
}));
