// import metadata from 'libphonenumber-js/metadata.min.json';
/* eslint-disable react/require-default-props */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input, { iconType } from './Input';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { checkPhone, ClientContext } from 'utils';
import { ClassNames, css } from '@emotion/core';
import { unit } from '../styles';
import { getContentDirection } from 'utils/i18n';
import { BusinessClass } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface PhoneInputProps extends React.HTMLAttributes<HTMLDivElement> {
  phone: string;
  onPhoneChange: (phone: string, valid: boolean, countryCode: string) => void;
  icon: iconType;
  businessInfo: BusinessClass;
  country?: string;
  children?: React.ReactNode;
}

export const PhoneInputComponent: React.FC<PhoneInputProps> = ({
  phone,
  country,
  onPhoneChange,
  icon,
  children,
  businessInfo,
}: PhoneInputProps) => {
  const nationalModeOn = businessInfo.backoffice_configuration?.enablePhoneNationalMode;
  const businessCountry = businessInfo.general_info.address?.[0]?.country.toLowerCase() || '';
  const setFirstLoadCountry = nationalModeOn ? businessCountry : country || businessCountry;
  const [countryCode, setCountryCode] = useState(setFirstLoadCountry);

  const { t } = useTranslation();
  const placeHolder = t('components.phoneInput.placeholder');
  const { theme, lang } = useContext(ClientContext);

  const containerClass = css({
    '&.react-tel-input': {
      width: '100%',
      maxWidth: unit * 29.2,
      '[dir="rtl"] &': {
        direction: 'ltr',
      },
    },
  });
  const inputClass = css({
    '&.form-control': {
      fontSize: unit * 2.6,
      lineHeight: 1.153,
      paddingTop: unit * 1.3,
      paddingBottom: unit * 1.3,
      height: 'auto',
      border: `1px solid transparent`,
      borderRadius: unit * 10,
      width: '100%',
      transition: 'all 0.3s ease-in-out',
      '&:focus': {
        borderColor: theme.mainColor,
      },
      '&, &.invalid-number, &.invalid-number:focus': {
        backgroundColor: theme.passiveColor,
      },
      '&.invalid-number, &.invalid-number:focus': {
        borderColor: 'red',
      },
    },
  });
  const buttonClass = css({
    '&.flag-dropdown, &.flag-dropdown.open': {
      width: unit * 5,
      height: unit * 5,
      padding: 0,
      top: 4,
      left: 4,
      border: 'none !important',
      '&, .selected-flag, .selected-flag.open': {
        background: 'none',
        borderRadius: '50%',
      },
      '.selected-flag, .selected-flag.open': {
        width: '100%',
        paddingLeft: unit,
      },
    },
  });

  const onChangePhone = (value: string, data: Record<string, unknown> | CountryData) => {
    if (data && data.countryCode && data.countryCode !== countryCode) {
      setCountryCode(data.countryCode as string);
    }
    const valid = checkPhone(value, data.countryCode as string);
    onPhoneChange(value, valid, (data.countryCode as string) || '');
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value || '';
    const valid = checkPhone(value, countryCode);
    return onPhoneChange(value, valid, countryCode || '');
  };

  if (!businessInfo || !businessInfo.backoffice_configuration?.enableExtendedPhone) {
    return (
      <Input
        required
        type="tel"
        name="phone"
        value={phone}
        placeholder={placeHolder}
        inputMode="numeric"
        icon={icon}
        data-testid="phone-input"
        autoComplete="username"
        onChange={onChangeInput}
        direction={getContentDirection(lang)}
      >
        {children}
      </Input>
    );
  }

  return (
    <ClassNames>
      {({ css: _ }) => (
        <PhoneInput
          disableCountryCode={nationalModeOn}
          country={countryCode}
          disableCountryGuess
          disableInitialCountryGuess
          disableDropdown={nationalModeOn}
          containerClass={_(containerClass)}
          inputClass={_(inputClass)}
          buttonClass={_(buttonClass)}
          /* eslint-disable
             @typescript-eslint/no-unsafe-member-access,
             @typescript-eslint/no-unsafe-call,
             @typescript-eslint/ban-types,
             @typescript-eslint/no-explicit-any,
             no-shadow
             */
          isValid={(value: string, country?: any): boolean => {
            return checkPhone(value, country?.iso2);
            /* eslint-enable
             @typescript-eslint/no-unsafe-member-access,
             @typescript-eslint/no-unsafe-call,
             @typescript-eslint/ban-types,
             @typescript-eslint/no-explicit-any,
             no-shadow
             */
          }}
          value={phone}
          placeholder={placeHolder}
          onChange={onChangePhone}
        />
      )}
    </ClassNames>
  );
};
