/**
 * Default translation for 'he_IL' language
 */

// tslint:disable-next-line:variable-name export-name
export const he_IL = {
  title: {
    authTitle: 'כניסה לאיזור הפרטי',
    changePass: 'צור סיסמה',
    home: 'דף הבית',
    homeEmpty: 'פרופיל חדש',
    profile: 'הפרופיל של, {{ userName }}',
    profileEdit: 'עריכת פרופיל',
    newRecord: 'רשומות חדשות',
    appointments: 'תורים',
    search: 'מצא את מה שאתה מחפש',
    teleApp: 'תורים און ליין',
    futureApp: 'תורים עתידיים',
    pastApp: 'ארכיון',
  },
  button: {
    ehr: 'EMR',
    back: 'חזור'
  },
  resource: 'רופא',
  common: {
    picker: {
      doneBtnLabel: 'בחר',
      cancelBtnLabel: 'ביטול',
    },
    currency: {
      USD: 'USD',
      RUB: 'RUR',
      RUR: 'RUR',
      ILS: 'ILS',
    },
    priceType: {
      equal: '',
      begin_with: 'מ',
      average: '',
      end: 'עד',
    },
    errors: {
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_MISMATCH:
        'Sorry, this timeslot is already taken. Please, select another one.',
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_UNAVAILABLE:
        'Selected doctor no longer provides this service. Please, select different doctor',
      RPC_ERROR__APPOINTMENT_CONFIRM_FAILED: 'Selected time is taken',
      RPC_ERROR__APPOINTMENT_CLIENT_OVERLAPPED:
        'You have already taken this time. Please try booking another one',
      RPC_ERROR__APPOINTMENT_CABINET_OVERLAPPED:
        'The cabinet you have selected is already taken. Please try booking another cabinet',
      RPC_ERROR__APPOINTMENT_CPACITY_REACH:
        'This class is fully booked. Please try booking different time.',
      RPC_ERROR__APPOINTMENT_RESOURCE_BUSY: 'העובד שנבחר כבר שובץ. תבחרו שעה אחרת או עובד אחר.',
      RPC_ERROR__CLIENT_IN_BLACKLIST: 'To make an appointment, please contact administrator',
      RPC_ERROR__APPOINTMENT_MIS_CONFIRM_FAILED:
        'Selected time in taken in the clinic. Consider booking different time, or another worker.',
      RPC_ERROR__APPOINTMENT_MIS_RESERVE_FAILED:
        'Selected time in taken in the clinic. Consider booking different time, or another worker.',
      RPC_ERROR_BODY:
        "We couldn't connect to the server. Please make sure that Internet is up and running.",
      RPC_ERROR_TITLE: 'Oops, seems like something has gone wrong!',
      SMS_CONFIRM_FAILED: 'SMS verification code is incorrect. Please try again',
      SMS_SEND_FAILED:
        "We couldn't send SMS. Please make sure that phone number you've entered is correct, then try again",
      SMS_CODE_INVALID: 'SMS verification code is incorrect. Please try again',
      JS_NO_SELECTED_RESOURCE: 'You did not select any worker!',
      JS_CRAC_NOT_ACTIVATED: 'CRAC is not activated',
      AUTH_EMAIL_TAKEN:
        'This user already exists! Please deactivate this user by dialing the Hotline Phone Number.',
      TIME_TO_CONFIRM_HAS_EXPIRED: 'התקשורת איטית או אין חיבור לרשת. תנסה מאוחר יותר.',
    },
  },
  modals: {
    appointmentCancel: {
      successCancelText: 'ההקלטה בוטלה בהצלחה',
      failCancelText: 'ביטול ההקלטה נכשל.',
      processCancelText: 'בטל את הערך',
      title: 'האם להמשיך בביטול התור',
      text: `אם תבטל תצטרך לקבוע תור מחדש במידת הצורך, או ללחוץ על שינוי כדי לשנות את התור`,
      textTelemed: 'אם תבטל, תצטרך להקליט מחדש. לא ניתן לבטל ביטול.',
      bntCancelText: 'ביטול',
      bntChangeText: 'שינוי',
      bntBackText: 'חזור',
    },
    ehrInfo: {
      title: 'רישום רפואי אלקטרוני',
      content: `כעת תוכלו לראות את היסטוריית הביקורים אליהם נרשמתם דרך האתר או האפליקציה.
ברגע שאתה מחבר את ה- EHR (רשומה רפואית אלקטרונית), אתה יכול לראות את כל הביקורים שלך במרפאה, כולל כאלו שנרשמת אליהם דרך הרישום, כמו גם להציג את תוצאות הבדיקות והמחקרים ולהוריד מרשמים.
EHR נמשך במרפאה באופן אוטומטי במהלך הפגישה הראשונה. אם היית בדלפק הקבלה לפחות פעם אחת, כנראה שיש לך EHR. ננסה להתאים את הנתונים שלך לנתוני המרפאה כדי למצוא אותם באופן אוטומטי.`,
      neverBeen: 'אם מעולם לא היית במרפאה,',
      doAppointment: 'הירשם אליו,',
      ehrAccess: 'ותקבל גישה למועצה המחרתית לאחר הביקור אצל הרופא',
      cancelBtn: 'עוד לא הייתי בפגישה',
      confirmBtn: 'יש לי EHR',
    },
  },
  components: {
    phoneInput: {
      placeholder: '.... ... .05',
    },
    passportIdInput: {
      placeholder: 'XXXXXXXXX',
    },
    fileInput: {
      chooseFile: 'בחר קובץ',
    },
    inputWithTooltip: {
      fillOutThisField: 'מלא/י שדה זה',
    },
    appointmentBlock: {
      cancel: 'ביטול תור',
      change: 'שינוי תור',
      open: 'לִפְתוֹחַ',
      start: 'להתחיל',
      infoChange: 'אינך יכול לתזמן פגישה זו, אך תוכל לבטל אותה.',
      infoSoon: 'ניתן לפתוח את חלון הפגישה הטלרפואי עם הרופא מראש.',
      infoStarted: 'הרופא כבר בקשר, התחל.',
      infoNotification: '10 דקות לפני ההתחלה תקבלו תזכורת בסמס',
      telemed: 'טלרפואה',
    },
    appointmentInfoBlock: {
      telemedInfo: `אתה יכול לתזמן פגישה זו מוקדם יותר מחצי שעה לפני ההתחלה.
עצה: השתמש באוזניות במהלך התקשורת, כך תישמע טוב יותר (ותוכל לשמוע את הרופא טוב יותר).`,
      telemedJoinButton: 'התחל לקחת באינטרנט',
      telemedCopyLink: 'העתק קישור ועידה: {{telemedLink}}',
      telemedLinkInClipboard: 'הקישור הועתק ללוח',
    },
    topNav: {
      newRecord: 'תור חדש',
      myRecords: 'תורים',
      ehr: 'כרטיס רפואי',
      profile: 'פּרוֹפִיל',
      record: 'תור חדש',
    },
    TopNavAppFilter: {
      allRecords: 'כל הרשומות',
      futureRecords: 'ביקורים קרובים',
      pastRecords: 'רשימת ביקורים',
      telemedRecords: 'טלמד',
      newRecord: 'תור חדש',
    },
    bottomFilters: {
      title: 'הקלט מסנן',
      info:
        'המסנן עוזר להסתיר רשומות מיותרות ולראות רק את אלה הדרושים כעת. המסנן מושבת כברירת מחדל.',
      month: 'חודש',
      year: 'שנה',
      location: 'ענף',
      service: 'שרות',
      resource: 'דוקטור',
      favorites: 'הצג מועדפים',
      noData: 'לא אותרה פעילות קודמת',
      cleanFilters: 'אתחול',
      bntBack: 'חזור',
      bntApply: 'להגיש מועמדות',
    },
    startRecord: {
      title: 'קביעת תור:',
      clinic: 'קליניקה',
      resource: 'רופא',
      service: 'שירות',
      waitMessage: 'אם הינך רואה את סיכום הביקור יש לחכות עד 24 שעות ואז יופיע',
    },
    newRecord: {
      restClinic: 'ביטול',
      restResource: 'ביטול',
      restService: 'ביטול',
    },
    auth: {
      bookAnAppointment: 'לקבוע תור',
      phoneNumber: 'מספר נייד',
      passportId: 'מספר ת"ז',
      password: 'סיסמה',
      getSmsCode: 'הזדהות בהודעה',
      resendSmsCode: 'SMS לא מגיע. קבל עוד אחד',
      smsCode: 'הזן את המספר מ- SMS:',
      firstVisit: 'ביקור ראשון',
      submitButtonLabel: 'שלח',
      forgetPasswordButtonLabel: 'שכחתה סיסמה?',
      rememberPasswordButtonLabel: 'זכרתי את הסיסמה שלי',
      weakPassword: 'הוזנה סיסמה חלשה',
      errorPhone: 'מספר טלפון לא תקין',
      errorPassportId: 'מספר תעודת הזהות שגוי. אנא בדקו את הפרטים.',
      errorSmsCode: 'שגיאה, קוד שגוי הוזן',
      error: 'סיסמה שוגיה',
    },
    newPassword: {
      label: 'צור סיסמא חדשה',
      info:
        'הסיסמה חייבת להכיל מספר אחד לפחות, אותיות גדולות ואות קטנה ואותה וכוללת יותר משבעה תווים',
      submit: 'שלח!',
      error: 'שגיאה, הסיסמה לא שונתה',
    },
    confirmation: {
      approve: 'אישור',
      withoutPayment: 'המשך בלי לשלם',
      onlinePay: 'מעבר לתשלום',
      ok: 'כן',
      cancel: 'בטל',
      retry: 'נסה שנית',
      confirmCode: 'קוד זיהוי',
      requestSmsCode: 'שלח קוד זיהוי',
      notReciveCode: 'האם קבלת קוד זיהוי?',
      sendCodeAgain: 'שלח קוד זיהוי שנית',
      attention: 'שימו לב, תוכלו להזמין את השירותים הבאים:',
      attentionDescription: '',
      minutesShort: 'min',
      save: 'שמור',
    },
    marker: {
      choose: 'בחר',
    },
    search: {
      filter: 'חיפוש:',
      filters: {
        all: 'הכל',
        locations: 'מיק',
        resources: 'רק רופאים',
        taxonomies: 'רק שירותים',
      },
      recentListTitle: 'חיפושים אחרונים:',
      doctorListTitle: 'הרופאים שלך',
      inputPlaceholder: 'יש להזין לפחות 3 תווים',
      category: 'התמחות',
      resource: 'רופא',
      business: 'מרפאה',
      EmptySearch: 'לא נמצאות נתונים',
    },
    resource: {
      anyAvailable: 'כל רופא',
    },
    visitsList: {
      closeBtn: 'סגור',
      locationTitle: 'מרפאה',
      addressTitle: 'כתובת',
      routBtn: 'Find a rout',
      rescheduleBtn: 'Reschedule',
      cancelBtn: 'ביטול',
      moreBtn: 'עוד על הביקור',
      noVisits: 'אין לך ביקורים מתוזמנים',
      noVisitsText: 'כדי להירשם, התחל בבחירת הפניה, סניף או רופא',
      noVisitResult: 'אין תוצאת ביקור',
      anamnesis: 'אנמנזה',
      diagnosis: 'אִבחוּן',
      medicalExaminationResult: 'תוצאות סקר',
      prescriptions: 'המלצות',
    },
    misAuth: {
      form: {
        title: 'מלא את הנתונים שלך',
        error: 'לא נמצאה התאמה. בדוק את הנתונים שלך',
        name: 'מלא את שמך ואת שם המשפחה',
        insuranceNumber: 'מספר ביטוח',
      },
      approve: 'לאשר',
    },
    misSelect: {
      title: 'בחר סוג ביטוח',
      skip: 'קביעת תור ללא ביטוח',
    },
    startScreen: {
      confirm: 'להמשיך',
    },
    profileForm: {
      label: 'אתה יכול לדלג על שלב זה, אבל אז אתה עדיין צריך למלא אותו כדי להגיע לרופא.',
      labalChangeData: 'עדכון תאריך',
      name: 'שם פרטי',
      middleName: 'שם האב',
      surname: 'שם משפחה',
      sex: 'מין',
      birthDate: 'ת. לידה',
      medCard: 'מספר כרטיס',
      passportId: 'ת.זהות',
      cancelButton: 'ביטול',
      cancelSkipButton: 'דלג',
      applyButton: 'עדכן',
      applyButtonFind: 'מצא אותי',
    },
    profileInfo: {
      changePhoto: 'שינוי תמונה',
      changeName: 'עדכון שם',
      changeGender: 'עדכון מין',
      changePhone: 'עדכון טלפון',
      addEmail: 'הוסף דוא"ל',
      usageAgreement: 'הסכם שימוש',
      logout: 'התנתק',
      logoutEMK: 'Выйти из ЭМК',
      removeProfile: 'מחיקת פרופיל',
      removeProfileModalTitle: 'אתה עומד למחוק לצמיתות את הפרופיל שלך',
      removeProfileModalDescription: 'הפעולה בלתי הפיכה. האם אתה בטוח שאתה רוצה לעשות זאת?',
      removeProfileModalCancelBtn: 'ביטול',
      removeProfileModalConfirmBtn: 'מחק',
      changeEmailModalTitle: 'הכנס דוא"ל כדי לשחזר נתונים בעת הצרוך',
      changeEmailModalDescription: `אם משהו משתבש (לדוגמה, אתה מאבד את מספר הטלפון הנייד שלך), אנו יכולים להחזיר את הגישה לנתונים שלך, בידיעה על הדוא"ל שלך.
      כמו כן, תוכל לקבל הודעות בדוא"ל על ביקורים קרובים, אך רק אם תרצה בכך.`,
      changeEmailModalInputPlaceholder: '@ כתובת דואר',
      changeEmailModalCancelBtn: 'ביטול',
      changeBirthDate: 'זה לא תאריך הלידה שלי',
      changeEmailModalConfirmBtn: 'אישור',
      changePhoneModalTitle: 'אתה הולך לקשר מספר נייד חדש במקום הישן',
      changePhoneModalDescription: `זה רלוונטי אם המספר שלך השתנה.
לא ניתן יהיה עוד להיכנס אל הישן ברגע שקשור חדש.
אם איבדת זמנית את הגישה למספר שלך ואתה חושש שמישהו עשוי להשתמש בו במקום אתה, אך מתכנן להחזיר את הגישה, ואז חסום את הגישה למספר הטלפון שלך באופן זמני. בזמן שהוא נעול
ניתן להזין את החשבון רק אם אתה יודע את הסיסמה (אי אפשר יהיה להתחבר באמצעות SMS). אנו ממליצים לך לקשר את הדוא"ל שלך כדי שתוכל לשחזר את הסיסמה שלך אם משהו ישתבש.`,
      changePhoneModalLabel: 'מספר טלפון חדש',
      changePhoneModalCancelBtn: 'בחזרה ל',
      changePhoneModalConfirmBtn: 'לשינוי מספר',
      changePhotoModalLabel: 'הוסף חדש',
      changePhotoModalTitle: 'שינוי תמונה',
      changePhotoModalConfirmBtn: 'העלה',
      changePhotoModalCancelBtn: 'חזור',
      removePhotoModalLabel: 'מחק',
    },
    agreement: {
      label: 'אני מסכים לעיבוד נתונים אישיים',
      info: 'לחץ לקריאה נוספת',
      modalClose: 'סגור',
      modalConfirm: 'לקבל',
    },
    passwordInfo: {
      short: {
        title: 'הסיסמה קצרה מדי',
        text: 'השתמש ב {{passLen}} לפחות',
      },
      wrong: {
        title: 'הסיסמה מכילה תווים אסורים',
        text: 'יש להשתמש רק באותיות של האלף-בית הלטיני, מספרים וסמלים `!@#$%^&*()_=+[]{};:"\\|,.',
      },
      weak: {
        title: 'סיסמה חלשה',
        text: 'החלף אות אחת או שתיים באותיות גדולות, הוסף מספרים',
      },
      strong: {
        title: 'סיסמה תקינה',
        text: 'מעולה: רק לא לשכות את הסיסמה',
      },
    },
  },
  navigation: {
    profile: {
      history: 'ביקורים',
      list: 'ביקורים',
      title: 'פרופיל',
      logOut: 'יציאה',
    },
    home: {},
    appointments: {
      confirm: 'אישור',
      category: 'בחר התמחות',
      location: 'מרפאה',
      resource: 'בחר רופא',
      service: 'בחר שירות',
      subcategory: 'בחר שירות',
      time: 'אנא בחר/י תאריך',
      cancel: 'ביטול',
      noTime: `סליחה, במהלך השבועות הקרובים הרופא לא זמין אתה יכול לבחור רופא, מרפאה, שירות אחרים`,
      unaveilableResource:
        'כדי למצוא רופא זה, לחץ על הכפתור למטה כדי לראות את רשימת כל הרופאים' +
        'מצא את הרופא הדרוש לך באופן ידני או באמצעות שדה החיפוש בחלקו העליון.',
      unaveilableResourceBtn: 'רשימת כל הרופאים',
    },
    chat: {
      title: 'Chat',
    },
    onHome: {
      title: 'House call',
    },
    login: {
      title: 'Log in',
    },
    pin: {
      title: 'Password creation',
    },
    logout: {
      title: 'Log out',
    },
    maps: {
      title: 'Map view',
    },
    recipes: {
      title: 'Prescriptions',
    },
    powered: {
      gbooking: 'Powered by GBooking',
      medMe: 'Powered by Med.me',
    },
  },
  tabbar: {
    appointments: 'Appointments',
    profile: 'Profile',
    chat: 'Chat',
    onHome: 'House call',
  },
  screens: {
    home: {
      loadMore: 'טען עוד...',
      pastVisits: 'רשימת ביקורים',
      filter: 'חיפוש',
      past: 'ביקורים קודמים',
      future: 'תורים עתידיים'
    },
    lock: {
      pinError: 'You have entered incorrect PIN',
    },
    ehr: {
      title: 'כרטיס רפואי',
      customProfileEditLabel:
        'אנא הכנס את הפרטים שלמטה כדי שנוכל להתאים את הפרופיל שלך לפרופיל EHR',
      inputSurnamePlaceholder: 'שם המשפחה שלך',
      inputPatronymicPlaceholder: 'השם האמצעי שלך',
      inputNamePlaceholder: 'השם שלך',
      birthdayPlaceholder: 'תאריך הלידה שלך',
      loginInfoText: `הזן את שם המשפחה והפטרונימי שלך \n כדי לקבל גישה \n לכרטיס הרפואי`,
      ok: 'בסדר!',
      hello: `אחר צהריים טובים, \${name}!`,
      ehrErrorTitle: `נראה שאין לך עדיין רישום רפואי \n`,
      ehrError: 'שגיאת בקשת EHR',
      ehrNotAvailable: 'כרגע EHR אינו זמין. נחזיר את הגישה בקרוב. אנא התאזר בסבלנות.',
      ehrAuthorizationError: 'שגיאת הרשאה! אנא בדוק את נכונות הנתונים שהוזנו!',
      ehrNotFind: 'EHR לא נמצא! אנא פנה למרפאה לגישה לכרטיס הרפואי שלך.',
      ehrConnectionFailed: 'יצירת החיבור נכשלה.',
      ehrDisabled: 'יצירת החיבור נכשלה.',
      notYou: 'זה לא אתה?',
      loginWithAnotherNumber: 'הזן תחת מספר אחר',
      callReception: `או התקשר לקבלה בטלפון \n טלפון \${טלפון}`,
      checkYourData: 'או בדוק את פרטיך',
      bookFirstAppointment: 'הירשם לפגישה הראשונה שלך',
      loginNumber: 'הזנת את המספר',
      loginError: 'מלא את כל השדות',
      introduceYourself: 'הצג את עצמך בבקשה:',
      genderLabel: 'קוֹמָה:',
    },
    appointments: {
      home: {
        btnsSelect: 'Select',
        visits: 'My visits',
        newFrom: {
          location: 'Select clinic location',
          profession: 'Select speciality',
          resource: 'Select doctor',
        },
        startHint: 'Start appointment creation:',
        errorSheetTitle: 'Network connection troubles detected!',
        errorSheetMessage:
          'Please make sure your internet connection is up and running, then try again.',
        reloadData: 'Reload data',
        cancel: 'Cancel',
        recentVisites: 'My last visits',
        startRecordingMessage: 'What would you like to start you booking from?',
        mainButton: {
          profession: {
            title: 'שירות',
          },
          location: {
            title: 'מרפאה',
          },
          resource: {
            title: 'רופא',
          },
        },
      },
      form: {
        nameLabel: 'שם מלא',
        name: 'שם פרטי',
        namePlaceholder: 'Enter your first name',
        surname: 'שם משפחה',
        surnamePlaceholder: 'Enter your family name',
        patronymic: 'Middle Name',
        patronymicPlaceholder: 'Enter your middle name',
        phone: 'Phone',
        professtion: 'Profession',
        insuranceNumber: 'Insurance number',
        direction: 'Speciality',
        address: 'מרפאה',
        date: 'תאריך',
        dateTime: 'תאריך',
        total: 'סה"כ',
        doctor: 'Doctor',
        businessType: 'Branch',
        discount: 'with discount',
        category: 'Category',
        subcategory: 'Subcategory',
        service: 'שירות',
        select: 'נא לבחור',
        time: 'Time',
        emailPlaceholder: 'example@email.com',
        emailLabel: 'דואר אלקטרוני',
        phoneLabel: 'הזן מספר טלפון',
        phonePlaceholder: '+1 123 456-78-90',
        addressInfo: {
          notify:
            'This clinic currently does not support online booking. Consider making an appointment by phone',
        },
        SearchInputPlaceholder: 'חיפוש לפי רופא או שירות',
        SingleSearchInputPlaceholder: 'חיפוש לפי רופא או שירות',
        title: 'סיום קביעת תור',
        description: 'הזנת פרטים לפני קביעת תור',
        notice: ' — שדות חובה',
        nameError: 'זוהה תוו לא חוקי',
        fio: 'שם מלא',
        genderLabel: 'מין',
        clientComment: 'הערת מטופל',
        birthdayLabel: 'תאריך לידה(dd.mm.yyyy)',
        birthdayLabelShort: 'תאריך לידה',
        genderMale: 'ז',
        genderFemale: 'נ',
        passportLabel: 'תעודת זהות',
        addressLabel: 'כתובת',
      },
      category: {
        search: 'חיפוש שירות',
      },
      splitInsuranceClient: {
        insuranceClient: 'I am an insurance patient',
        commercialClient: 'I will cover the appointment by myself',
        return: 'Return',
        message: 'To make an appointment, please call',
      },
      subcategory: {
        title: 'אנא בחר/י באחת האפשרויות מטה',
        search: 'חיפוש שירות',
        skip: 'עבור לבחירת שירותים',
      },
      service: {
        title: 'אנא בחר/י עיר מגורים לקביעת השירות',
        search: 'חיפוש שירות',
        from: 'מ',
        to: 'עד',
        beginWith: 'מתחיל מ',
      },
      resources: {
        search: 'חיפשור לפי שם רופא או שירות',
        anyBtn: 'כל רופא',
        anyOverlay: 'כל רופא',
        all: 'כל הרופאים',
        favorites: 'רופאים מועדפים',
        available: 'זמין מ ',
        onlyCall: 'זימון תור טלפוני',
        experience: 'שנות וותק',
        notAvailable: 'Online appoint-ment N/A',
        clinic: 'מרפאה',
        availableAddress: 'רופא זמין במרפאות:',
        about: 'אודות',
        nextAvailable: 'תאריך זמין הבא ',
        book: 'קבע',
        desriptionTitle: 'אודות רופא',
        closeBtn: 'סגור',
      },
      address: {
        search: 'חיפוש לפי כתובת',
        listOption: 'בסביבה',
        mapOption: 'מפה',
        anyOverlay: 'כל מרפאה',
        offline: 'זימון תור און ליין לא זמין',
        measure: 'ק"ם',
        list: 'תצוגת רשימה',
        map: 'תצוגת מפה',
        book: 'קביעת תור',
        title: 'בחר מרפאה',
        disable: 'זימון תור און ליין לא זמין',
      },
      time: {
        businessError:
          'Sorry, but online booking is not configured yet for the selected clinic. ' +
          'You can go back and select different clinic.',
        error:
          'Sorry, but there are no available doctors for the selected speciality within upcoming few weeks. ' +
          'You can go back and select different speciality or location.',
        networkErrorTitle: 'An error occurred while loading data.',
        networkErrorMessage: 'Please make sure that internet is up and running, then try again.',
        ok: 'OK',
        showAvailableSlots: 'להציג מרווחי זמן תפוסים',
        errorResourceSlots:
          'Sorry, but there are no available timeslots for the selected doctor, we will ' +
          'show you available timeslots of another worker, instead',
        showDescription: "Show specialist's description",
        hideDescription: 'Hide description',
      },
      confirm: {
        total: 'סה"כ',
        smsCodeHint: 'הזדהות חד פעמית באמצעות מסרון',
        smsCodePlaceholder: 'קוד שהתקבל במסרון',
        phonePlaceholder: 'הכנס מספר טלפון',
        birthdatePlaceholder: 'dd.mm.yyyy',
        passportPlaceholder: 'תעודת זהות',
        done: 'Done',
        doneDescription: 'Your appointment has been successfully created',
        codeErrorTitle: 'You have entered incorrect code',
        phoneErrorTitle: 'You have entered incorrect phone number',
        codeErrorDescription: 'Please enter correct code',
        phoneErrorDescription: 'Correct format: +11231234567',
        thankYou: 'התור נקבע בהצלחה. תישארו בריאיים',
        newRecord: 'קביעת תור נוסף',
        agreeText: 'מסכים לקבלת מסרונים ותזכורות',
        agreeLink: 'קרא עוד',
        payInProgressTitle: 'מעבר לתשלום',
        payInProgressTextTop: `נא למלא פרטי אשראי בחלון חדש שנפתח`,
        payInProgressTextMiddle: `אם החלון לא נפתח נא ללחוץ כאן`,
        payInProgressTextBottom1: `שיניתם את דעתכם?&nbsp;`,
        payInProgressTextBottom2: ` לביטול`,
        payInProgressTextBottom3: `&nbsp;וחזרה לעמוד הקודם`,
      },
      disable: {
        message: 'Online appointment creation is currently not available',
        text: `We are sorry for the inconvenience caused, but online booking
           is currenly out of operation in this business.<br /></br>
           To make an appointment, please call: </br>
          <a href='tel:\${cleanedPhone}'>\${phone}</a>`,
      },
      phoneClinic: 'טלפון מרפאה:',
      noAppFound: 'רשומה לא נמצאה',
      download: 'להורדה',
      dataLoadedSuccess: 'הנתונים הועלו',
    },
    profile: {
      history: {
        reRegister: 'Make another appointment',
        edit: 'Edit',
        cancel: 'Cancel',
        inactive: 'This appointment is inactive',
      },
      main: {
        twoFAUserDescription: 'PatientID (EHR)',
        appointmentBlockTitle: 'Appointment to the doctor',
        map: 'Route',
        connectedProfiles: 'LINKED PROFILES',
        authorizationWarning:
          'You need to create an appointment in order to get authenticated in the system.',
        select: 'Select',
        nearVisits: 'UPCOMING VISITS',
        historyOfVisits: 'History of visits',
        recipes: 'Prescriptions',
        createNew: 'Create new appointment',
        createNewInfo: "You don't have any appointments yet",
      },
      splash: `User profile is currently under development
  It will be released soon, but you are welcome
  to provide us with your feedback`,
    },
    common: {
      splash: {
        btnTitle: 'Create Feedback letter',
        emailTitle: 'Personal Cabinet of the Patient. Feedback form',
        emailBody: `Dear development team!
          My message:

          My name is:
          Please use the following phone number to get in touch with me: \${phone}

          Thank you`,
      },
    },
    recipes: {
      splash: `Module «Prescriptions» is currently under development
  It will be released soon, but you are welcome
  to provide us with your feedback`,
    },
    main: {
      splash: `Module «News and Special Offers» is currently under development
  It will be released soon, but you are welcome
  to provide us with your feedback`,
    },
    chat: {
      splash: `Module «Chat» is currently under development
  It will be released soon, but you are welcome
  to provide us with your feedback`,
    },
    onHome: {
      splash: `Module «House Call» is currently under development
  It will be released soon, but you are welcome
  to provide us with your feedback`,
    },
    login: {
      loginPlaceholder: 'Login',
      codePlaceholder: 'Code',
      enterBtnTitle: 'Enter',
      info: 'By registering, you accept the terms and conditions of the license agreement',
    },
    logout: {
      actiontitle: 'Profile deletion',
      actionmessage:
        'After completing this action, all your data will be deleted, and you will need to activate the app once again',
      closeAppMessage: 'Are you sure you want to close this application?',
      logOut: 'Quit',
      cancel: 'Return',
      closeApp: 'Exit app',
    },
    code: {
      error: 'The code you have entered is incorrect! Please try again.',
      resend: 'Send the code once again',
      delay: "You'll be able to request your new code once again in:",
      enterCode: 'Please enter below the code that has been sent to your phone number:',
    },
    maps: {
      splash: `Map view for clinics is currently under development
  It will be released soon, but you are welcome
  to provide us with your feedback`,
    },
    pin: {
      enterCode: 'Enter code',
      recoverCode: 'Forgot your code?',
      touchIdTitle: 'Confirm action',
      info: 'Create your permanent password OR enable TouchID',
    },
    eula: {
      info: 'To continue, please accept our license agreement',
    },
  },
};
