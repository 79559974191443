/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Resolvers } from 'apollo-boost';

import * as api from './api';
import { Client } from './createApolloClient';
import { IChangePass, IUpdateUserInfo, GlobalSearchType, IUpdateClientInfo } from './types';
import { medMeServices } from 'data-layer/helpers';
import moment from 'moment';
import { config, getPhone, urlManager, constants } from 'utils';
import { EHR } from 'medme-ehr-js-sdk';
import {
  fakeEhrApp,
  fakeEhrDiagnostic,
  getAppointmentResult,
  parseDiagnostic,
  parseDiagnostics,
  parseResponce,
} from './helpers/parseEHR';
import { AppointmentMessage } from 'medme-ehr-js-sdk/dist/es5/messages/AppointmentMessage';
import { IDiagnosticReportMessage } from 'medme-ehr-js-sdk/dist/es5/interfaces';
import { AppointmentResultMessage } from 'medme-ehr-js-sdk/dist/es5/messages/AppointmentResultMessage';
import { Cred, Gender, Sex } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { getAnyBusinessID } from 'utils/anyBusinessID';

type Context = {
  client: Client;
};

const saveUserData = ({
  data,
  client,
}: {
  data: {
    token: string;
    expires: string;
    user: string;
    askNewPass: boolean;
    smsCode?: string;
  };
  client: Client;
}) => {
  client.writeData({
    data: {
      applyAgreementStatus: data.askNewPass,
      smsCode: data.smsCode || '',
    },
  });
};

export const getApiUrl = (): string => {
  const GBOOKING_COREV2_RPC_URL = config.CORE_API_ENDPOINT;

  if (!GBOOKING_COREV2_RPC_URL) {
    throw new Error('Expected GBOOKING_COREV2_RPC_URL to be set');
  }

  return GBOOKING_COREV2_RPC_URL;
};

export const resolvers: Resolvers = {
  Query: {
    searchInBusiness: async (
      _root,
      {
        search,
        businessID,
        networkID,
        type,
      }: {
        search: string;
        businessID?: string;
        networkID?: string;
        type?: GlobalSearchType;
      },
    ) => {
      const GBOOKING_COREV2_RPC_URL = getApiUrl();

      const data = await api.searchInBusiness({
        search,
        type,
        networkId: networkID,
        businessId: businessID,
        GBOOKING_COREV2_RPC_URL,
      });

      return {
        ...data,
        __typename: 'SearchInBusinessResult',
        resources: (data.resources || []).map((r) => {
          return {
            ...r,
            middleName: r.middleName || '',
            profession: r.profession || '',
            description: r.description || '',
            image: r.image || '',
            extraId: r.extraId || '',
            siteId: r.siteId || '',
            __typename: 'Resource',
          };
        }),
        taxonomies: (data.taxonomies || []).map((t) => {
          return {
            ...t,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            name: (t.alias && t.alias['ru-ru']) || '',
            extraId: t.extraId || '',
            siteId: t.siteId || '',
            __typename: 'Taxonomy',
          };
        }),
        locations: (data.locations || []).map((l) => {
          return {
            ...l,
            internalID: l.internalID || '',
            general_info: {
              ...l.general_info,
              address: l.general_info.address?.map((a) => {
                return {
                  ...a,
                  latitude: a.latitude || '',
                  longitude: a.longitude || '',
                  __typename: 'AddressSchema',
                };
              }),
              __typename: 'BusinessGeneralInfo',
            },
            __typename: 'LocationSearchResult',
          };
        }),
      };
    },
    getPatientAppointments: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        size = 6,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        size: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];
      if (offset > 0) {
        // todo: page loading from ehr
      }

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointments',
            appointments: appData || [],
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentService.getPatientAppointments(
          patientId,
          size,
          offset,
          (err, appointments) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }
            const appData = parseResponce(appointments, clientVisits);
            resolve({
              __typename: 'PatientAppointments',
              appointments: appData || [],
            });
          },
          true,
        );
      });
    },
    getPatientResultsCount: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentsCount',
            count: appData.length,
            support: false,
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentResultService.getPatientAppointmentResultsCount(
          patientId,
          (err, count, support) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }

            resolve({
              __typename: 'PatientAppointmentsCount',
              count,
              support,
            });
          },
        );
      });
    },
    getPatientAppointmentResultsCount: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];
      if (offset > 0) {
        // todo: page loading from ehr
      }

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentsCount',
            count: appData.length,
            support: false,
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentService.getPatientAppointmentsCount(
          patientId,
          (err, count, support) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }

            resolve({
              __typename: 'PatientAppointmentsCount',
              count,
              support,
            });
          },
        );
      });
    },
    getEHRDiagnosticsCount: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentsCount',
            count: appData.length,
            support: false,
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.diagnosticReportService.getPatientDiagnosticReportsCount(
          patientId,
          (err, count, support) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }

            resolve({
              __typename: 'PatientAppointmentsCount',
              count,
              support,
            });
          },
        );
      });
    },
    getPatientAppointmentResults: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        size = 6,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        size: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];
      if (offset > 0) {
        // todo: page loading from ehr
      }

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentResults',
            appointmentResults: appData || [],
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentResultService.getPatientAppointmentResults(
          // medmeServices.appointmentResultService.getPatientAppointmentResults(
          patientId,
          size,
          offset,
          (err, appointments) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }
            const appData = (appointments || []).map(getAppointmentResult);

            resolve({
              __typename: 'PatientAppointmentResults',
              appointmentResults: appData || [],
            });
          },
          true,
        );
      });
    },
    getEHRAppointmentResult: async (
      _root,
      {
        businessID,
        clientId,
        visitId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        visitId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
    ) => {
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointment_results.json');
          const fake: fakeEhrApp = await req.json();
          const fakeRes = fake.result.appointments.find((app) => app.id === visitId);
          if (fakeRes !== undefined) {
            const res = (fakeRes as unknown) as AppointmentResultMessage;
            const appResult = getAppointmentResult(res);
            return {
              ...appResult,
              __typename: 'PatientAppointmentResult',
            };
          }
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        medmeServices.appointmentResultService.getAppointmentResultById(visitId, (err, res) => {
          if (res && res.id) {
            const appResult = getAppointmentResult(res);
            resolve({
              ...appResult,
              __typename: 'PatientAppointmentResult',
            });
          }
          if (err) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ message: 'screens.ehr.ehrError' });
          }
        });
      });
    },
    getEHRDiagnostics: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        size = 6,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        size: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_diagnostic_reports.json');
          const fake: fakeEhrDiagnostic = await req.json();
          const clientDiagnostics = fake.result.diagnosticReports;

          const appData = parseDiagnostics(clientDiagnostics);
          return {
            __typename: 'PatientDiagnostics',
            diagnostics: appData || [],
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.diagnosticReportService.getPatientDiagnosticReports(
          patientId,
          size,
          offset,
          (err: any, reports: IDiagnosticReportMessage[]) => {
            if (err) {
              // ignore unknown error from api
              if (err?.code === -32603) {
                return resolve({
                  __typename: 'PatientDiagnostics',
                  diagnostics: [],
                });

              } else if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              return reject({ message: 'screens.ehr.ehrError' });
            }
            const clientDiagnostics: IDiagnosticReportMessage[] = reports || [];

            const appData = parseDiagnostics(clientDiagnostics);
            resolve({
              __typename: 'PatientDiagnostics',
              diagnostics: appData || [],
            });
          },
          true,
        );
      });
    },
    getEHRDiagnosticResult: async (
      _root,
      {
        businessID,
        clientId,
        visitId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        visitId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
    ) => {
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_diagnostic_reports.json');
          const fake: fakeEhrDiagnostic = await req.json();
          const fakeRes = fake.result.diagnosticReports.find((app) => app.id === visitId);
          if (fakeRes !== undefined) {
            const res = (fakeRes as unknown) as IDiagnosticReportMessage;
            const appResult = parseDiagnostic(res);
            return {
              ...appResult,
              __typename: 'PatientAppointmentResult',
            };
          }
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        medmeServices.diagnosticReportService.getDiagnosticReportById(visitId, (err, res) => {
          if (res && res.id) {
            const appResult = parseDiagnostic(res);
            resolve({
              ...appResult,
              __typename: 'PatientAppointmentResult',
            });
          }
          if (err) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ message: 'screens.ehr.ehrError' });
          }
        });
      });
    },
  },
  Mutation: {
    login: async (
      _,
      { phone, password, country }:
        { phone: string; password: string; country: string},
      ctx: Context,
    ) => {
      const { client } = ctx;
      const { country_code, area_code, number } = getPhone(phone, country);
      const formattedPhoneString = `${country_code}${area_code}${number}`;
      const loginResult = await api.loginUser(formattedPhoneString, password);
      if (loginResult && loginResult.token) {
        saveUserData({
          client,
          data: {
            smsCode: password,
            ...loginResult,
          },
        });
        return {
          __typename: 'UserToken',
          ...loginResult,
        };
      }

      return Promise.reject(new Error(''));
    },
    requestSmsCode: async (
      _root,
      { phone, country, passportId, lang }: { phone: string; country: string, passportId: string, lang: string },
    ) => {
      let businessID: string = urlManager.getBusinessId() || '';
      const networkID = urlManager.getNetworkId();
      if (!businessID && networkID) {
        businessID = getAnyBusinessID() ?? '';
      }
      const formattedPhone = getPhone(phone, country);
      const result = await api.requestSmsCode(formattedPhone, businessID, passportId, lang);
      if (result && result.token) {
        return { token: result.token, __typename: 'SmsToken' };
      }

      return Promise.reject(new Error(result.error || 'bad phone'));
    },
    confirmSmsCode: async (
      _root,
      { code, token }: { code: string; token: string },
      ctx: Context,
    ) => {
      const { client } = ctx;
      const businessID = urlManager.getBusinessId();
      const data = {
        code,
        token,
        businessID,
      };
      const result = await api.confirmSmsCode(data);
      if (result && result.token) {
        const clientData = {
          smsCode: code,
          ...result,
        };
        saveUserData({ client, data: clientData });
        return { __typename: 'UserToken', ...clientData };
      }

      return Promise.reject(new Error(result.error || 'bad code'));
    },
    changePassword: async (_root, { id, password, currentPassword }: IChangePass, ctx: Context) => {
      const changePass = await api.changePass({ id, password, currentPassword });
      return changePass;
    },
    updateUserInfo: async (
      _root,
      {
        name,
        middleName,
        surname,
        gender,
        birthday,
        user,
        token,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        medCardId,
        passportId,
      }: {
        name: string;
        middleName: string;
        surname: string;
        birthday: string;
        gender: Gender;
        user: string;
        token: string;
        medCardId?: string;
        passportId: string;
      },
      ctx: Context,
    ) => {
      const { client } = ctx;
      const updateUserInfo: IUpdateUserInfo = {
        name,
        middleName,
        surname,
        gender,
        birthday,
        passportId,
      };
      const cred: Cred = {
        user,
        token,
      };
      updateUserInfo.birthday = moment.utc(updateUserInfo.birthday, constants.DATE_FORMAT).toDate();
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const updateUserInfoResult: boolean = await api.updateUserInfo(
        updateUserInfo,
        cred,
        GBOOKING_COREV2_RPC_URL,
      );

      return updateUserInfoResult;
    },
    setPatientId: (_root, { id }: { id: string }, ctx: Context) => {
      const { client } = ctx;
      client.writeData({ data: { patientId: id } });

      return true;
    },
    updateClientInfo: async (
      _root,
      {
        id,
        email,
        name,
        middleName,
        surname,
        sex,
        birthday,
        user,
        token,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        medCardId,
        passportId,
      }: {
        id: string;
        email: string;
        name: string;
        middleName: string;
        surname: string;
        birthday: string;
        sex: Sex;
        user: string;
        token: string;
        medCardId?: string;
        passportId: string;
      },
    ) => {
      const updateClientInfo: IUpdateClientInfo = {
        id,
        email,
        name,
        middleName,
        surname,
        sex,
        birthday,
        passportId,
      };
      const cred: Cred = {
        user,
        token,
      };
      updateClientInfo.birthday = moment.utc(updateClientInfo.birthday, constants.DATE_FORMAT).toDate();
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const updateUserInfoResult: boolean = await api.updateClientInfo(
        updateClientInfo,
        cred,
        GBOOKING_COREV2_RPC_URL,
      );

      return updateUserInfoResult;
    },
  },
};
