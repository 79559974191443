/* eslint-disable quotes */
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import * as IUserInfo from 'data-layer/mutations/__graphql__/UpdateUserInfo';
import {
  AgreementFrame,
  PageContainer,
  Button,
  LogoutButton,
  ModalDialog,
  Input,
  CodeInput,
  ProfileForm,
  ProfilePicBlock,
  UserPic,
  ScrollBlock,
  useAuth,
} from '../components';
import { getAvatar, getMultilineTranslation } from 'utils/utils';
import {
  unit,
  largeText,
  mediumText,
  smallText,
  breakpointLarge,
  breakpointMedium,
} from '../styles';
import { paths } from 'utils/routing';

import { SMS_CODE_LENGTH } from '../components/loginSmsForm';
import { ClientContext, constants, navigation } from 'utils';
import { UPDATE_USER_INFO } from 'data-layer/mutations';
import { ICred } from 'data-layer/types/ICred';
import { ITheme, SexEHR } from 'data-layer/types';
import moment from 'moment';
import InsuranceInfoTable from '../components/InsuranceInfoTable';
import { useRemovePhoto } from 'shared/api/photo/removePhoto';
import { useUpdatePhoto } from 'shared/api/photo/updatePhoto';
import { useUploadTempFile } from 'shared/api/file/uploadTempFile';
import { UPDATE_CLIENT_INFO } from 'data-layer/mutations/UpdateClientInfo';
import { UpdateClientInfo, UpdateClientInfoVariables } from 'data-layer/mutations/__graphql__/UpdateClientInfo';
import { IUserCombinedInfo } from 'data-layer/types/IUserCombinedInfo';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileInfoScreenProps extends RouteComponentProps { }

const ProfileInfoScreen: React.FC<ProfileInfoScreenProps> = () => {
  const fileInput = useRef<HTMLInputElement>(null);
  const PHOTO_TYPES = ['image/jpg', 'image/jpeg'];
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const { theme } = clientContext;
  const cred: ICred = {
    user: clientContext.user,
    token: clientContext.token,
  };
  const { updateUserData } = useAuth();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [removeProfileModal, setRemoveProfileModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [changePhoneModal, setChangePhoneModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);
  // const [fileName, setFileName] = useState('');
  const [fileToken, setFileToken] = useState('');
  const [updatePhotoError, setUploadPhotoError] = useState(false);
  const [encodedImg, setEncodedImg] = useState('');

  useEffect(() => {
    if(clientContext.clientData){
      clientContext.refetchClient();
    }
  }, []);
  const { updatePhoto } = useUpdatePhoto();
  const { removePhoto } = useRemovePhoto();
  const { uploadTempFile } = useUploadTempFile();

  const requireAgreement =
    clientContext.businessInfo?.widget_configuration.requireAgreement;
  const requireAgreementLink =
    clientContext.businessInfo?.widget_configuration.requireAgreementLink;

  const [updateUserInfo, updateUserInfoStatus] = useMutation<
      IUserInfo.UpdateUserInfo,
      IUserInfo.UpdateUserInfoVariables
    >(UPDATE_USER_INFO);
  const [updateClientInfo, updateClientInfoStatus] = useMutation<
      UpdateClientInfo,
      UpdateClientInfoVariables
    >(UPDATE_CLIENT_INFO);
  const onSave = async (param: { variables: IUserCombinedInfo }) => {
    await updateUserInfo({
      variables: {
        ...param.variables,
        ...cred,
      },
    })
    await updateClientInfo({
      variables: {
        ...param.variables,
        ...cred,
      },
    })
    setShowEditProfile(false);
    clientContext.refetchClient();
  }
  

  const phoneString = clientContext.clientInfo.phone;
  const [userPhone, setUserPhone] = useState(
    clientContext.nationalMode ? `${phoneString}` : `+${phoneString}`,
  );
  const {
    name = '',
    surname = '',
    middleName = '',
    gender = SexEHR.NOT_SPECIFIED,
    email,
    date,
    contractAttachments = [],
  } = clientContext.clientInfo;
  const birthDate = date ? moment.utc(date).format(constants.DATE_FORMAT) : '';
  const getGenderStr = (g: number) => {
    switch (g) {
      case 0:
        return t('screens.appointments.form.genderMale');
      case 1:
        return t('screens.appointments.form.genderFemale');
      default:
        return t('screens.appointments.form.genderSelect');
    }
  };
  // const onShowRemoveDialog = () => {
  //   setRemoveProfileModal(true);
  // };
  const onCancelRemoveDialog = () => {
    setRemoveProfileModal(false);
  };
  const onConfirmRemoveDialog = () => {
    setRemoveProfileModal(false);
  };
  // const onShowEmailDialog = () => {
  //   setChangeEmailModal(true);
  // };
  const onCancelEmailDialog = () => {
    setChangeEmailModal(false);
  };
  const onConfirmEmailDialog = () => {
    setChangeEmailModal(false);
  };
  // const onShowPhoneDialog = () => {
  //   setChangePhoneModal(true);
  // };
  const onCancelPhoneDialog = () => {
    setChangePhoneModal(false);
  };
  const onConfirmPhoneDialog = () => {
    setChangePhoneModal(false);
  };

  const renderProfileEdit = () => {
    if (updateUserInfoStatus.loading || updateClientInfoStatus.loading) {
      return <>Loading</>;
    }
    if (updateUserInfoStatus.error || updateClientInfoStatus.error) {
      return <>Error</>;
    }
    return (
      <ProfileForm
        onSave={onSave}
        cred={cred}
        clientContext={clientContext}
        showSkipBtn
        customLabel={t('components.profileForm.labalChangeData')}
        onSkip={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigation.goBack();
        }}
      />
    );
  };
  const onLoadPhoto = () => {
    void updatePhoto(fileToken, {
      onSuccess(data) {
        if (data) {
          setUploadPhotoError(false);
          clientContext.refetchClient();
          setShowPhotoUpload(false);
        }
      },
    });
    setEncodedImg('');
  };
  const onRemovePhoto = () => {
    void removePhoto(undefined, {
      onSuccess(data) {
        if (data) {
          setUploadPhotoError(false);
          clientContext.refetchClient();
        }
      },
    });
    setEncodedImg('');
  };

  const onCancelPhotoUpload = () => {
    setShowPhotoUpload(false);
    setEncodedImg('');
  };
  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    if (files && files[0]?.name && PHOTO_TYPES.includes(files[0]?.type)) {
      const fr = new FileReader();
      fr.onload = ({ target }) => {
        if (target && target.result) {
          setEncodedImg(target.result as string);
        } else {
          setEncodedImg('');
        }
      };
      fr.readAsDataURL(files[0]);
      // const selectFileName = files[0]?.name;
      const fileTokenUpload: string = await uploadTempFile(files[0]);
      if (fileTokenUpload) {
        setFileToken(fileTokenUpload);
        // setFileName(selectFileName);
      }
    }
  };

  const onFileInputClick = () => {
    if (fileInput.current) fileInput.current.click();
  };

  const updatePhotoModal = () => {
    return (
      <ModalDialog
        isOpen={showPhotoUpload}
        title={t('components.profileInfo.changePhotoModalTitle')}
        confirmButtonTitle={t('components.profileInfo.changePhotoModalConfirmBtn')}
        cancelButtonTitle={t('components.profileInfo.changePhotoModalCancelBtn')}
        onRequestClose={onCancelPhotoUpload}
        onConfirm={onLoadPhoto}
        onCancel={onCancelPhotoUpload}
      >
        <InputWrapper>
          <HiddenInput
            required
            ref={fileInput}
            type="file"
            name="filename"
            value=""
            accept="image/jpeg"
            multiple={false}
            onChange={onFileChange}
          />
          <UserPicStyled
            textPlaceholder
            size={unit * 17}
            src={encodedImg || getAvatar(clientContext.clientInfo.iconURL)}
            onClick={onFileInputClick}
          />
        </InputWrapper>
        <ErrorText>{updatePhotoError && t('components.profileInfo.updatePhotoError')}</ErrorText>
        <LinkStyledDiv theme={theme} onClick={onRemovePhoto}>
          {t('components.profileInfo.removePhotoModalLabel')}
        </LinkStyledDiv>
      </ModalDialog>
    );
  };

  const renderProfileInfo = () => {
    return (
      <>
        <Inner>
          <ProfilePicBlockStyled
            userPicSrc={getAvatar(clientContext.clientInfo.iconURL)}
            onFileChange={() => setShowPhotoUpload(true)}
          />
          {updatePhotoModal()}
          <Row>
            <ColTop>
              <Caption theme={theme}>{`${name} ${middleName} ${surname}`}</Caption>
              <LinkStyledDiv theme={theme} onClick={() => setShowEditProfile(true)}>
                {t('components.profileInfo.changeName')}
              </LinkStyledDiv>
              {/* <ButtonEmail theme={theme} variant="link" onClick={onShowEmailDialog}>
              {t('components.profileInfo.addEmail')}
            </ButtonEmail> */}
              <ModalDialog
                isOpen={changeEmailModal}
                title={t('components.profileInfo.changeEmailModalTitle')}
                confirmButtonTitle={t('components.profileInfo.changeEmailModalConfirmBtn')}
                cancelButtonTitle={t('components.profileInfo.changeEmailModalCancelBtn')}
                onRequestClose={onCancelEmailDialog}
                onConfirm={onCancelEmailDialog}
                onCancel={onConfirmEmailDialog}
              >
                {getMultilineTranslation(t('components.profileInfo.changeEmailModalDescription'))}
                <Input
                  placeholder={t('components.profileInfo.changeEmailModalInputPlaceholder')}
                  style={{ margin: `${unit}vh 0` }}
                />
              </ModalDialog>
            </ColTop>
            <ColGender>
              <Caption theme={theme}>{getGenderStr(gender)}</Caption>
              <LinkStyledDiv theme={theme} onClick={() => setShowEditProfile(true)}>
                {t('components.profileInfo.changeGender')}
              </LinkStyledDiv>
            </ColGender>
            <ColTop>
              <Caption theme={theme}>{userPhone}</Caption>
              {/* <ButtonStyled theme={theme} variant="link" onClick={onShowPhoneDialog}>
              {t('components.profileInfo.changePhone')}
            </ButtonStyled> */}
              <ModalDialog
                isOpen={changePhoneModal}
                title={t('components.profileInfo.changePhoneModalTitle')}
                confirmButtonTitle={t('components.profileInfo.changePhoneModalConfirmBtn')}
                cancelButtonTitle={t('components.profileInfo.changePhoneModalCancelBtn')}
                onRequestClose={onCancelPhoneDialog}
                onConfirm={onCancelPhoneDialog}
                onCancel={onConfirmPhoneDialog}
              >
                {getMultilineTranslation(t('components.profileInfo.changePhoneModalDescription'))}
                <div style={{ margin: `${unit}vh 0` }}>
                  <PhoneLabel>{t('components.profileInfo.changePhoneModalLabel')}</PhoneLabel>
                  <Input
                    required
                    type="tel"
                    name="phone"
                    value={userPhone}
                    placeholder="+7 ..."
                    inputMode="numeric"
                    onChange={(event) => {
                      setUserPhone(event.target.value);
                    }}
                  />
                  <CodeLabel>{t('components.auth.smsCode')}</CodeLabel>
                  <CodeInput
                    length={SMS_CODE_LENGTH}
                    onChange={(code) => {
                      // eslint-disable-next-line no-console
                      // celnoos.log(code);
                    }}
                  />
                </div>
              </ModalDialog>
            </ColTop>
          </Row>
          <Row>
            {birthDate ? (
              <ColTop>
                <Caption theme={theme}>{`${birthDate}`}</Caption>
                <LinkStyledDiv theme={theme} onClick={() => setShowEditProfile(true)}>
                  {t('components.profileInfo.changeBirthDate')}
                </LinkStyledDiv>
              </ColTop>
            ) : (
              ''
            )}
            {email ? (
              <ColTop>
                <Caption theme={theme}>{`${email}`}</Caption>
              </ColTop>
            ) : (
              <ColTop>
                <Caption theme={theme}> </Caption>
              </ColTop>
            )}
          </Row>
          <InsuranceInfoTable />
          <Footer>
            <ColBottom>
              <ModalDialog
                isOpen={agreementModal}
                cancelButtonTitle={t('components.agreement.modalClose')}
                onCancel={() => setAgreementModal(false)}
                onRequestClose={() => setAgreementModal(false)}
              >
                <AgreementFrame src={requireAgreementLink} />
              </ModalDialog>
              {requireAgreement && requireAgreementLink && (
                <ButtonStyled theme={theme} variant="link" onClick={() => setAgreementModal(true)}>
                  {t('components.profileInfo.usageAgreement')}
                </ButtonStyled>
              )}
              <LogoutButton>{t('components.profileInfo.logout')}</LogoutButton>
              {
                // Seems we don't need it right now
                /* <ButtonStyled theme={theme} variant="link">
              {t('components.profileInfo.logoutEMK')}
            </ButtonStyled> */
              }
            </ColBottom>
            <ColBottom style={{ flexDirection: 'column-reverse' }}>
              {/* <ButtonStyled theme={theme} variant="link" onClick={onShowRemoveDialog}>
              <IconDeleteStyled />
              {t('components.profileInfo.removeProfile')}
            </ButtonStyled> */}
              <ModalDialog
                isOpen={removeProfileModal}
                title={t('components.profileInfo.removeProfileModalTitle')}
                cancelButtonTitle={t('components.profileInfo.removeProfileModalCancelBtn')}
                confirmButtonTitle={t('components.profileInfo.removeProfileModalConfirmBtn')}
                onRequestClose={onCancelRemoveDialog}
                onCancel={onCancelRemoveDialog}
                onConfirm={onConfirmRemoveDialog}
              >
                {t('components.profileInfo.removeProfileModalDescription')}
              </ModalDialog>
            </ColBottom>
          </Footer>
        </Inner>
      </>
    );
  };
  return (
    <PageContainer
      layoutClassName="profile"
      title={t('title.profile', { userName: name })}
      contentCSS={{ alignItems: 'stretch' }}
      topNav
      backLink={paths.homePath}
    >
      <ScrollBlock>{showEditProfile ? renderProfileEdit() : renderProfileInfo()}</ScrollBlock>
    </PageContainer>
  );
};
export default ProfileInfoScreen;
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const ProfilePicBlockStyled = styled(ProfilePicBlock)({
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginBottom: unit * 3,
  },
});

const LinkStyledDiv = styled('div')(smallText, (props: { theme: ITheme }) => ({
  color: props.theme.mainColor,
  cursor: 'pointer',
  textDecoration: 'none',
}));

const DescriptionDiv = styled('div')(smallText, (props: { theme: ITheme }) => ({
  color: props.theme.chevronColor,
}));

const ButtonStyled = styled(Button)({
  textAlign: 'initial',
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

// const ButtonEmail = styled(ButtonStyled)({
//   marginTop: unit * 6,
//   [`@media screen and (max-width: ${breakpointMedium}px)`]: {
//     marginTop: unit * 3,
//   },
// });

const ColTop = styled('div')({
  paddingTop: unit * 7,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    paddingTop: unit * 2,
    paddingBottom: unit * 2,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    paddingTop: 0,
    paddingBottom: unit * 3,
    textAlign: 'center',
  },
});

const ColGender = styled(ColTop)({
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    display: 'none',
  },
});

const ColBottom = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '&:not(:last-child)': {
    [`[dir="ltr"] &`]: {
      marginRight: unit,
    },
    [`[dir="rtl"] &`]: {
      marginLeft: unit,
    },
  },
  '& > *:not(:last-child)': {
    marginBottom: unit * 3.2,
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: `${unit * 3}px !important`,
    },
  },
});

const UserPicStyled = styled(UserPic)({
  cursor: 'pointer',
});

const Inner = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  [`@media screen and (min-width: ${breakpointMedium + 1}px)`]: {
    [`[dir="ltr"] &`]: {
      paddingLeft: unit * 19.2,
    },
    [`[dir="rtl"] &`]: {
      paddingRight: unit * 19.2,
    },
  },
});

const ErrorText = styled('div')({
  color: 'red',
});

const Row = styled('div')({
  display: 'flex',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    justifyContent: 'flex-start',
    '& > *:not(:last-child)': {
      [`[dir="ltr"] &`]: {
        marginRight: unit * 7,
      },
      [`[dir="rtl"] &`]: {
        marginLeft: unit * 7,
      },
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    flexDirection: 'column',
  },
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    alignItems: 'center',
  },
});

const Caption = styled('div')(mediumText, (props: { theme: ITheme }) => ({
  color: props.theme.textColor,
  marginBottom: unit * 1.7,
}));

const Footer = styled('div')({
  display: 'flex',
  marginTop: 'auto',
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    flexDirection: 'column',
  },
});

// const IconDeleteStyled = styled(IconDelete)({
//   marginRight: unit,
// });

const PhoneLabel = styled('div')(largeText, {
  fontWeight: 400,
  marginBottom: unit * 2,
});

const CodeLabel = styled('div')(smallText, {
  fontWeight: 500,
  margin: `${unit * 3}px 0 ${unit * 2}px`,
});

const InputWrapper = styled('div')({
  margin: `${unit}vh 0`,
});

const HiddenInput = styled('input')({
  display: 'none',
});
