import { Profile, ProfileGetProfileInfoRequestParams } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { RefetchOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { MedMeAPI } from '@gbooking/ts-sdk';

export const useGetProfileInfo = (
  contractId?: string,
  contractExtraId?: string,
  options?: { skip?: boolean },
): {
  error: unknown,
  data?: Profile,
  isFetching: boolean,
  isLoading: boolean,
  refetch: (options?: RefetchOptions) => Promise<UseQueryResult>
} => {

  let params: ProfileGetProfileInfoRequestParams = {}
  if (contractId || contractExtraId) {
    params = {
      contract: {
        id: contractId,
        extraId: contractExtraId,
      }
    };
  }
  const { error, data, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: () => MedMeAPI.profile.getProfileInfo(params),
    refetchOnWindowFocus: false,
    staleTime: 0,
    retry: 0,
    enabled: !options?.skip,
  });

  return { isLoading, error, data, isFetching, refetch }
}