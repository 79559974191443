import { ClientClass, ClientFindOfCreateClientResponseResult, ClientFindOrCreateClientRequestParams, ContractAttachmentsSchema } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { RefetchOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { MedMeAPI } from '@gbooking/ts-sdk';
import moment from 'moment';

export const useGetClient = (
  businessId = '',
  networkId = '',
  contractExtraId?: string,
  options?: { skip?: boolean },
): {
  error: unknown,
  data?: ClientClass,
  isFetching: boolean,
  isLoading: boolean,
  refetch: (options?: RefetchOptions) => Promise<UseQueryResult>
} => {
  const params: ClientFindOrCreateClientRequestParams = {
    business: {
      id: businessId,
    },
  };

  if (networkId) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    params.network = {
      id: networkId,
    };
  }
  if (contractExtraId) {
    params.contract = {
      contractExtraId,
    };
  }
  const { error, data, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['client'],
    queryFn: () => MedMeAPI.client.findOrCreateClient(
      params,
    ).then((res: ClientFindOfCreateClientResponseResult) => {
      return {
        ...res.client,
        contractAttachments:
          (res.client.contractAttachments || [])?.map((p: ContractAttachmentsSchema) => {
            return {
              ...p,
              dateBegin: p.dateBegin ? moment(p.dateBegin).format('DD.MM.YYYY') : '',
              dateEnd: p.dateBegin ? moment(p.dateEnd).format('DD.MM.YYYY') : '',
            } as ContractAttachmentsSchema;
          }) ?? [],
      };
    }),
    refetchOnWindowFocus: false,
    staleTime: 1000,
    retry: 0,
    enabled: !options?.skip,
  });

  return { isLoading, error, data, isFetching, refetch }
}