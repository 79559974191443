import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import Axios, { AxiosResponse } from 'axios';
import { config } from 'utils';

type FileToken = {
  token: string;
}

/**
 * Upload file, return file token
 * @param file
 */
export const useUploadTempFile = (): {
  uploadTempFile: UseMutateAsyncFunction<string, unknown, File, unknown>,
} => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    {
      mutationKey: ['photos'],
      mutationFn: (file: File) => {
        const formData = new FormData();
        formData.append('filename', file, file.name);
        return Axios.post('upload_temp_file', formData, {
          baseURL: config.CORE_API_ENDPOINT.replace('/rpc', '/'),
        }).then((res: AxiosResponse<FileToken>) => res.data?.token);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(['photos'])
      },
    },
  );

  return { uploadTempFile: mutateAsync };
};