/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';

import { createRequest } from './createRequest';
import { urlManager } from 'utils/urlManager';
import { Cred } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { IContractInfo, IUpdateClientInfo, IUpdateClientInfoRequest, IUpdateUserInfo, IUpdateUserInfoRequest } from 'data-layer/types';

export async function updateUserInfo(
  userInfo: IUpdateUserInfo,
  cred: Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<boolean> {
  const params: IUpdateUserInfoRequest = {
    name: userInfo.name,
    middleName: userInfo.middleName,
    surname: userInfo.surname,
    birthday: userInfo.birthday,
    gender: userInfo.gender,
    passportId: userInfo.passportId,
  };
  const businessId = urlManager.getBusinessId();
  const networkId = urlManager.getNetworkId();
  const contractID = urlManager.getContractId();
  const contractExtraId = urlManager.getContractExtraId();
  if (contractID || contractExtraId) {
    params.contract = {} as IContractInfo;
    if (contractID) {
      params.contract.id = contractID;
    }
    if (contractExtraId) {
      params.contract.extraId = contractExtraId;
    }
    if (businessId) {
      params.contract.businessID = businessId;
    }
    if (networkId) {
      params.contract.networkID = networkId;
    }
  }
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('profile.update_profile_info', params, cred))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<boolean>;
}

export async function updateClientInfo(
  clientInfo: IUpdateClientInfo,
  cred: Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<boolean> {
  const businessId = urlManager.getBusinessId();
  const networkId = urlManager.getNetworkId();
  const contractID = urlManager.getContractId();
  const contractExtraId = urlManager.getContractExtraId();
  const params: IUpdateClientInfoRequest = {
    client: {
      id: clientInfo.id,
      email: [clientInfo.email],
      name: clientInfo.name,
      middleName: clientInfo.middleName,
      surname: clientInfo.surname,
      birthday: clientInfo.birthday,
      sex: clientInfo.sex,
      passportId: clientInfo.passportId,
    },
    business: {
      id: businessId,
    },
    network: {
      id: networkId,
    },
  };
  if (contractID || contractExtraId) {
    params.contract = {} as IContractInfo;
    if (contractID) {
      params.contract.id = contractID;
    }
    if (contractExtraId) {
      params.contract.extraId = contractExtraId;
    }
  }
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('client.update_client_info', params, cred))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<boolean>;
}