import {
  BusinessClass, BusinessGetNetworkDataResponseResult,
} from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

export const getDisablePasswordLogin = (
  businessId: string,
  networkId: string,
  businessInfo?: BusinessClass,
  network?: Partial<BusinessGetNetworkDataResponseResult>,
): boolean => {
  return (networkId
    ? network?.businessConfiguration?.disablePasswordLoginInClientCabinet
    : businessId
    ? businessInfo?.backoffice_configuration?.disablePasswordLoginInClientCabinet
    : false)
    ?? false;
};
