import { BusinessId, Field } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { useQuery } from '@tanstack/react-query';
import { MedMeAPI } from '@gbooking/ts-sdk';

export const useGetExtraFields = (
  businessID?: string,
): {
  error: unknown,
  data?: Field[],
  isFetching: boolean,
  isLoading: boolean,
} => {
  const params = {
    business: {
      id: businessID,
    },
  } as BusinessId;
  const { error, data, isFetching, isLoading } = useQuery({
    queryKey: ['extraFields'],
    queryFn: () => MedMeAPI.field.getExtraFields(params).then((res: Field[]) => res),
    refetchOnWindowFocus: false,
    staleTime: 1000,
    retry: 0,
  });

  return { isLoading, error, data, isFetching }
}