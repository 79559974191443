import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import Button from './button';
import { ClientContext, urlManager } from 'utils';
import { medMeServices } from 'data-layer/helpers';
import { useAuth } from './AuthProvider';
import { getStrictLoginInClientCabinet } from 'utils/getStrictLogin';

type ILogoutButtonProps = React.HTMLAttributes<HTMLButtonElement>;

const LogoutButton = ({ children }: ILogoutButtonProps): JSX.Element => {
  const clientContext = useContext(ClientContext);
  const { removeToken } = useAuth();
  const { theme } = clientContext;
  const medmeServices = medMeServices({
    ...clientContext,
  });
  const client = useApolloClient();
  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (clientContext.patientId) {
      try {
        medmeServices.authService.removeAuthentication(() => {
          // callback for error handling
        });
      } catch (error) {
        //
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.resetStore();
    removeToken(
      getStrictLoginInClientCabinet(urlManager.getBusinessId(), urlManager.getNetworkId(), clientContext.businessInfo, clientContext.network),
      urlManager.getBusinessId(),
      urlManager.getNetworkId(),
    );
  };
  return (
    <Button theme={theme} variant="link" data-testid="logout-button" onClick={onClick}>
      {children}
    </Button>
  );
};

LogoutButton.defaultProps = {
  children: undefined,
};

export default LogoutButton;
