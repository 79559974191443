import { gql } from 'apollo-boost';

export const UPDATE_CLIENT_INFO = gql`
  mutation UpdateClientInfo(
    $id: String!
    $email: [String!]
    $name: String!
    $middleName: String!
    $surname: String!
    $birthday: String!
    $sex: Sex!
    $user: String!
    $token: String!
    $medCardId: String
    $passportId: String!
  ) {
    updateClientInfo(
      id: $id
      email: $email
      name: $name
      middleName: $middleName
      surname: $surname
      birthday: $birthday
      sex: $sex
      user: $user
      token: $token
      medCardId: $medCardId
      passportId: $passportId
    ) @client
  }
`;
