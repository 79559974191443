import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER_INFO } from 'data-layer/mutations';
import * as IUserInfo from 'data-layer/mutations/__graphql__/UpdateUserInfo';
import { PageContainer, ProfileForm, useAuth } from '../components';
import { ICred } from 'data-layer/types/ICred';
import { ClientContext, navigation } from 'utils';
import { size } from '../styles';
import { paths } from 'utils/routing';
import { IUserCombinedInfo } from 'data-layer/types/IUserCombinedInfo';
import { UPDATE_CLIENT_INFO } from 'data-layer/mutations/UpdateClientInfo';
import { UpdateClientInfo, UpdateClientInfoVariables } from 'data-layer/mutations/__graphql__/UpdateClientInfo';

type ProfileEditScreenProps = RouteComponentProps;

const ProfileEditScreen: React.FC<ProfileEditScreenProps> = () => {
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const cred: ICred = {
    user: clientContext.user,
    token: clientContext.token,
  };
  const { userData, updateUserData } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateUserInfo, updateUserInfoStatus] = useMutation<
    IUserInfo.UpdateUserInfo,
    IUserInfo.UpdateUserInfoVariables
  >(UPDATE_USER_INFO);
  const [updateClientInfo, updateClientInfoStatus] = useMutation<
    UpdateClientInfo,
    UpdateClientInfoVariables
  >(UPDATE_CLIENT_INFO);
  const showProfileScreen = userData.showProfileScreen;
  const SkipUpdateUserInfo = () => {
    updateUserData({ showProfileScreen: false, askNewPass: false });
  }
  const onSkip = () => {
    return showProfileScreen ? SkipUpdateUserInfo() : navigation.goBack();
  };

  const onSave = async (param: { variables: IUserCombinedInfo }) => {
    await updateUserInfo({
      variables: {
        ...param.variables,
        ...cred,
      },
    })
    await updateClientInfo({
      variables: {
        ...param.variables,
        ...cred,
      },
    })
    updateUserData({ showProfileScreen: false, askNewPass: false });
    clientContext.refetchClient();
  }
  const cancelButtonTitle = showProfileScreen
    ? t('components.profileForm.cancelSkipButton')
    : t('components.profileForm.cancelButton');
  const requireAgreement =
    clientContext.businessInfo?.widget_configuration.requireAgreement;
  const requireAgreementLink =
    clientContext.businessInfo?.widget_configuration.requireAgreementLink;
  const agreementLink =
    showProfileScreen && requireAgreement && requireAgreementLink ? requireAgreementLink : '';
  const contentCSS = {
    paddingBottom: 0,
    marginLeft: !showProfileScreen ? size.sideNavWidth : 0,
  };

  return (
    <PageContainer
      title={t('title.profileEdit')}
      layoutClassName="profile-edit"
      contentCSS={contentCSS}
      backLink={paths.homePath}
    >
      <ProfileForm
        onSave={onSave}
        clientContext={clientContext}
        cred={cred}
        showSkipBtn={showProfileScreen}
        cancelButtonTitle={cancelButtonTitle}
        agreementLink={agreementLink}
        onSkip={onSkip}
      />
    </PageContainer>
  );
};
export default ProfileEditScreen;
