import { MedMeAPI } from '@gbooking/ts-sdk';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

export const useRemovePhoto = (): {
  removePhoto: UseMutateAsyncFunction<boolean, unknown, void, unknown>;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationKey: ['photos'],
    mutationFn: () => MedMeAPI.profile.removeImage({ remove_image: true }),
    onSuccess: () => {
      void queryClient.invalidateQueries(['photos']);
    },
  });
  return { removePhoto: mutateAsync };
};
