import {
  BusinessClass,
  BusinessGetNetworkDataResponseResult,
} from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

export const getStrictLoginInClientCabinet = (
  businessId: string,
  networkId: string,
  businessData?: BusinessClass,
  networkData?: Partial<BusinessGetNetworkDataResponseResult>,
): boolean => {
  return (
    (networkId
      ? networkData?.businessConfiguration?.strictLoginInClientCabinet
      : businessId
      ? businessData?.backoffice_configuration?.strictLoginInClientCabinet
      : false) ?? false
  );
};
