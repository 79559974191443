/**
 * manager for URL processing
 */

import { BusinessClass, BusinessGetNetworkDataResponseResult } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

export class urlManager {
  public static getQueryParam = (param: string): string => {
    const vars = window.location.search.substring(1).split('&');
    const val = vars.find((pairValue: string) => {
      const pair = pairValue.split('=');

      return pair[0] === param;
    });
    if (val) {
      const pair = val.split('=');

      return pair[1];
    }

    return '';
  };

  public static getQueryString = (): string => {
    return window.location.search.substring(1);
  };

  public static getBusinessId = (): string => {
    return urlManager.getQueryParam('BUSINESS_ID');
  };

  public static getNetworkId = (): string => {
    return urlManager.getQueryParam('NETWORK_ID');
  };

  public static getContractExtraId = (): string => {
    return urlManager.getQueryParam('contractExtraId');
  };

  public static getContractId = (): string => {
    return urlManager.getQueryParam('contractID');
  };

  public static getClearClientData = (): string => {
    return urlManager.getQueryParam('clearClientData');
  };

  public static getTimeout = (): string => {
    return urlManager.getQueryParam('timeout');
  };

  public static getLogoUrl({
    networkID,
    businessID,
    networkData,
    businessData,
  }: {
    networkID: string;
    businessID: string;
    networkData?: Partial<BusinessGetNetworkDataResponseResult>;
    businessData?: BusinessClass;
  }): string {
    let res = '';
    if (networkID) {
      const businesses =
        networkData?.businesses;
      const businessWithLogo = (businesses || []).find(
        (b) => !!b.info?.general_info?.logo_url
      );
      res = businessWithLogo?.info?.general_info?.logo_url || '';
    } else if (businessID) {
      res = businessData?.general_info?.logo_url || '';
    }
    return res.trim() || '/logo.svg';
  }
}
