import React, { useState } from 'react';

import ModalBasic from './ModalBasic';

import { urlManager } from 'utils/urlManager';
import { navigate } from '@reach/router';
import { paths } from 'utils/routing';
import {config} from "../utils";
import { MODAL_ELEMENT_ID } from 'pages/newRecordScreen';

export interface IWidgetModalParams {
  url?: string;
  networkId?: string;
  business?: string;
  screen?: string;
  taxonomy?: string;
  resourceId?: string;
}

// eslint-disable-next-line import/no-mutable-exports
let modalCallback: any;
export const openWidget = (params: IWidgetModalParams) => {
console.log(params)
  modalCallback ? modalCallback(params) : navigate(`${paths.newRecordPath}`);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WidgetModal = () => {
  const [opened, setOpened] = useState(false);
  const [widgetParams, setWidgetParams] = useState<IWidgetModalParams | undefined>();

  const insertWidget = (): void | undefined => {
    if (!widgetParams) {
      return;
    }
    if (!widgetParams.business && !widgetParams.networkId) {
      const businessId = urlManager.getBusinessId();
      const networkId = urlManager.getNetworkId();
      if (networkId) {
        widgetParams.networkId = networkId;
      } else if (businessId) {
        widgetParams.business = businessId;
      }
    }
    widgetParams.url = config.WIDGET_URL;
    // eslint-disable-next-line no-unused-expressions
    window.GBooking && window.GBooking.openWidget(widgetParams);
  };

  React.useEffect(() => {
    modalCallback = (params: IWidgetModalParams) => {
      setOpened(true);
      setWidgetParams(params);
    };
    return () => { modalCallback = null; }
  }, []);

  const closeWidget = () => {
    // eslint-disable-next-line no-unused-expressions
    window.GBooking && window.GBooking.closeWidget();
    setOpened(false);
  };

  return (
    <ModalBasic
      variant="widget"
      id={MODAL_ELEMENT_ID}
      isOpen={opened}
      onAfterOpen={insertWidget}
      onRequestClose={closeWidget}
    />
  );
};

export default WidgetModal;
