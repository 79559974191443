import { initJsonRpcMedMeAPI } from '@gbooking/ts-sdk';

export interface IConfig {
  CORE_API_ENDPOINT: string,
  OAUTH_URL: string,
  CUSTOMER_PORTAL_URL: string;
  WIDGET_URL: string;
  CDN_URL: string;
  CDN_WORKER_IMG_URL: string;
  CDN_USER_IMG_URL: string;
  AUTH_TOKEN: string;
  EHR_URL: string;
  EHR_REQUEST_APPOINTMENTS_LIMIT: number;
  MOCK_EHR: boolean;
  CRAC_SLOTS_API_ENDPOINT: string,
  CRAC_API_ENDPOINT: string,
  CRAC3_API_ENDPOINT: string,
  OAUTH_OTP_SEND: string,
  OAUTH_OTP_VERIFY: string,
  OAUTH_OTP_WEBLOGIN: string,
  JSONRPC_REQUEST_DEBUG: boolean,
  OTP_REQUEST_DEBUG: boolean,
}

export const constants = {
  devConfig: process.env.REACT_APP_HOST_TYPE === 'dev' || false,
  localConfig: process.env.REACT_APP_HOST_TYPE === 'development' || false,
  defaultResourceImage: '/widget/doctor_img.png',
  DATE_FORMAT: 'DD.MM.YYYY',
  LANGUAGES: [
    {
      title: 'Русский',
      tag: 'ru',
      code: 'ru-ru',
      countries: ['ru'],
      dateFormat: 'D MMMM YYYY, HH:mm',
    },
    {
      title: 'עברית',
      tag: 'il',
      code: 'he-il',
      countries: ['il'],
      dateFormat: 'D MMMM YYYY, HH:mm',
    },
    {
      title: 'English',
      tag: 'en',
      code: 'en-us',
      countries: ['uk', 'us'],
      dateFormat: 'MMMM D, YYYY, hh:mm A',
    },
  ],
  MOCK_PATIENT_ID: '10041760',
};

const localConfig: IConfig = {
  CORE_API_ENDPOINT: 'http://apiv2.gbooking.local:9000/rpc',
  OAUTH_URL: 'http://oauth.gbooking.local:9000',
  OAUTH_OTP_SEND: 'http://oauth.gbooking.local:9000/sms/code',
  OAUTH_OTP_VERIFY: 'http://oauth.gbooking.local:9000/sms/verify',
  OAUTH_OTP_WEBLOGIN: 'http://oauth.gbooking.local:9000/web_login',
  CUSTOMER_PORTAL_URL: 'http://my.gbooking.local:9000',
  WIDGET_URL: 'http://widget.gbooking.local:9000/',
  CDN_URL: 'http://cdn.gbooking.local:9000',
  CDN_WORKER_IMG_URL: 'http://cdn.gbooking.local:9000/images/workers/',
  CDN_USER_IMG_URL: 'http://cdn.gbooking.local:9000/images/client_avatar/',
  AUTH_TOKEN: '55251467c4698837838b4567',
  EHR_URL: 'http://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
  CRAC_SLOTS_API_ENDPOINT: 'http://cracslots.gbooking.local:9000/rpc',
  CRAC_API_ENDPOINT: 'http://crac.gbooking.local:9000/rpc',
  CRAC3_API_ENDPOINT: 'http://crac.gbooking.local:9000/rpc',
  JSONRPC_REQUEST_DEBUG: true,
  OTP_REQUEST_DEBUG: true,
}

const devConfig: IConfig = {
  CORE_API_ENDPOINT: 'https://apiv2.dev.gbooking.ru/rpc',
  OAUTH_URL: 'https://oauth.dev.gbooking.ru',
  OAUTH_OTP_SEND: 'https://oauth.dev.gbooking.ru/sms/code',
  OAUTH_OTP_VERIFY: 'https://oauth.dev.gbooking.ru/sms/verify',
  OAUTH_OTP_WEBLOGIN: 'https://oauth.dev.gbooking.ru/web_login',
  CUSTOMER_PORTAL_URL: 'https://my.dev.gbooking.ru',
  WIDGET_URL: 'https://widget.dev.gbooking.ru/',
  CDN_URL: 'https://cdn.dev.gbooking.ru',
  CDN_WORKER_IMG_URL: 'https://cdn.dev.gbooking.ru/images/workers/',
  CDN_USER_IMG_URL: 'https://cdn.dev.gbooking.ru/images/client_avatar/',
  AUTH_TOKEN: '55251467c4698837838b4567',
  EHR_URL: 'https://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
  CRAC_SLOTS_API_ENDPOINT: 'https://cracslots.dev.gbooking.ru/rpc',
  CRAC_API_ENDPOINT: 'https://crac.dev.gbooking.ru/rpc',
  CRAC3_API_ENDPOINT: 'https://crac.dev.gbooking.ru/rpc',
  JSONRPC_REQUEST_DEBUG: true,
  OTP_REQUEST_DEBUG: true,
}

const prodConfigDefault: IConfig = {
  CORE_API_ENDPOINT: 'https://apiv2.gbooking.ru/rpc',
  OAUTH_URL: 'https://oauthv2.gbooking.ru',
  OAUTH_OTP_SEND: 'https://oauthv2.gbooking.ru/sms/code',
  OAUTH_OTP_VERIFY: 'https://oauthv2.gbooking.ru/sms/verify',
  OAUTH_OTP_WEBLOGIN: 'https://oauthv2.gbooking.ru/web_login',
  CUSTOMER_PORTAL_URL: 'https://my.gbooking.ru',
  WIDGET_URL: 'https://widget.med.me/',
  CDN_URL: 'https://cdn.gbooking.ru',
  CDN_WORKER_IMG_URL: '//cdn.gbooking.ru/images/workers/',
  CDN_USER_IMG_URL: '//cdn.gbooking.ru/images/client_avatar/',
  AUTH_TOKEN: '55251467c4698837838b4567',
  EHR_URL: 'https://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
  CRAC_SLOTS_API_ENDPOINT: 'https://cracslots.gbooking.ru/rpc',
  CRAC_API_ENDPOINT: 'https://crac.gbooking.ru/rpc',
  CRAC3_API_ENDPOINT: 'https://crac.gbooking.ru/rpc',
  JSONRPC_REQUEST_DEBUG: false,
  OTP_REQUEST_DEBUG: false,
}

// TODO: uncomment if med.me will be a separate system
// export const prodConfigMedMe: IConfig = {
//   CORE_API_ENDPOINT: 'https://apiv2.med.me/rpc',
//   OAUTH_URL: 'https://oauth.med.me',
//   OAUTH_OTP_SEND: 'https://oauth.med.me/sms/code',
//   OAUTH_OTP_VERIFY: 'https://oauth.med.me/sms/verify',
//   OAUTH_OTP_WEBLOGIN: 'https://oauth.med.me/web_login',
//   CUSTOMER_PORTAL_URL: 'https://my.med.me',
//   WIDGET_URL: 'https://widget.med.me/',
//   CDN_URL: 'https://cdn.med.me',
//   CDN_WORKER_IMG_URL: '//cdn.med.me/images/workers/',
//   CDN_USER_IMG_URL: '//cdn.med.me/images/client_avatar/',
//   AUTH_TOKEN: '55251467c4698837838b4567',
//   EHR_URL: 'https://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
//   EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
//   MOCK_EHR: false,
//   CRAC_SLOTS_API_ENDPOINT: 'https://cracslots.med.me/rpc',
//   CRAC_API_ENDPOINT: 'https://crac.med.me/rpc',
//   CRAC3_API_ENDPOINT: 'https://crac.med.me/rpc',
//   JSONRPC_REQUEST_DEBUG: false,
//   OTP_REQUEST_DEBUG: false,
// }

const prodConfigMedMeRu: IConfig = {
  CORE_API_ENDPOINT: 'https://apiv2.med-me.ru/rpc',
  OAUTH_URL: 'https://oauth.med-me.ru',
  OAUTH_OTP_SEND: 'https://oauth.med-me.ru/sms/code',
  OAUTH_OTP_VERIFY: 'https://oauth.med-me.ru/sms/verify',
  OAUTH_OTP_WEBLOGIN: 'https://oauth.med-me.ru/web_login',
  CUSTOMER_PORTAL_URL: 'https://my.med-me.ru',
  WIDGET_URL: 'https://widget.med-me.ru/',
  CDN_URL: 'https://cdn.med-me.ru',
  CDN_WORKER_IMG_URL: '//cdn.med-me.ru/images/workers/',
  CDN_USER_IMG_URL: '//cdn.med-me.ru/images/client_avatar/',
  AUTH_TOKEN: '55251467c4698837838b4567',
  EHR_URL: 'https://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
  CRAC_SLOTS_API_ENDPOINT: 'https://cracslots.med-me.ru/rpc',
  CRAC_API_ENDPOINT: 'https://crac.med-me.ru/rpc',
  CRAC3_API_ENDPOINT: 'https://crac.med-me.ru/rpc',
  JSONRPC_REQUEST_DEBUG: false,
  OTP_REQUEST_DEBUG: false,
}

const configs: Record<string, IConfig> = {
  'gbooking.local': localConfig,
  'dev.gbooking.ru': devConfig,
  'gbooking.ru': prodConfigDefault,
  'med-me.ru': prodConfigMedMeRu,
}

const host = window.location.hostname;
const parts = host.split('.');
parts.shift();

export const config: IConfig = configs[parts.join('.')] ?? prodConfigDefault;

export const initMedMeSDK = (): void => {
  initJsonRpcMedMeAPI(config);
};