/* eslint-disable quotes */
/* eslint-disable react/require-default-props */
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import renderHTML from 'react-render-html';
import styled from '@emotion/styled';
import copyToClipboard from 'copy-to-clipboard';

import UserPic from './UserPic';
import {
  unit,
  smallText,
  mediumText,
  breakpointLarge,
  largeText,
  showForMobile,
  hideForMobile,
} from '../styles';

import { Map, Placemark, YMaps } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';
import { AppointmentEditBlock, Button, ScrollBlock } from 'components';
import { ClientContext, config, getMultilineTranslation } from 'utils';
import { ReactComponent as IconSuccess } from '../assets/icon-success.svg';
import { ITheme } from '../data-layer/types';
import { darken } from 'polished';
import { AppointmentExtraFields } from './AppointmentExtraFields/AppointmentExtraFields';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { Appointment, ExtraField } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

interface AppointmentInfoBlockProps {
  appointmentData: Appointment;
}

const CLIPBOARD_TIMEOUT = 5000;

export const AppointmentInfoBlock: React.FC<AppointmentInfoBlockProps> = ({
  appointmentData,
}: AppointmentInfoBlockProps) => {
  const { appointment, resource, taxonomy, client, telemedData, extraFields: appointmentExtraFields } = appointmentData;
  const [clipboardTimerId, setClipboardTimerId] = useState<number | undefined>();
  const { start } = appointment;
  const { t } = useTranslation();
  const { theme, lang, getResourceName, generalInfo } = useContext(ClientContext);
  const startDate = moment.utc(start).calendar(null, { sameElse: lang.dateFormat });

  const address = generalInfo?.address?.[0]?.address || '';
  const center: number[] =
    generalInfo?.address?.[0]?.latitude && generalInfo?.address?.[0]?.longitude
      ? [
        parseFloat(generalInfo?.address?.[0]?.latitude),
        parseFloat(generalInfo?.address?.[0]?.longitude),
      ]
      : [55.751574, 37.573856]; // some fakse data
  const zoom = 13;
  const telemedLink = telemedData?.shortJoinUrl || telemedData?.joinUrl || '';

  const onSubmitExtraFields = (extraFields: ExtraField[]) => {
    return MedMeAPI.appointment.updateAppointment({
      ...appointmentData,
      appointment: {
        ...appointmentData.appointment,
        start: appointmentData.appointment.start.toString(),
        price: {
          ...appointmentData.appointment.price,
          originalAmount: appointmentData.appointment.price.originalAmount ?? undefined
        }
      },
      extraFields,
    });
  }

  const clearClipboardTimeout = () => {
    if (clipboardTimerId) {
      clearTimeout(clipboardTimerId);
    }
    setClipboardTimerId(undefined);
  };

  const setClipboardTimeout = () => {
    const timerId = window.setTimeout(clearClipboardTimeout, CLIPBOARD_TIMEOUT);
    setClipboardTimerId(timerId);
  };

  useEffect(() => clearClipboardTimeout, []);

  const getBusinessPhone = () => {
    const phone = generalInfo?.phone?.[0];
    if (!phone) return '';
    return `+${phone.country_code}(${phone.area_code})${phone.number}`;
  };

  const getLocationPhone = (needStyles?: boolean) => {
    const phone = getBusinessPhone();
    if (phone) {
      if (!needStyles) {
        return `${t('screens.appointments.phoneClinic')} ${phone}`;
      }
      return (
        <Location theme={theme}>
          <span>{t('screens.appointments.phoneClinic')}</span>
          <a href={`tel://${phone}`}>{phone}</a>
        </Location>
      );
    }
    return '';
  };

  const getProperties = () => {
    const placemarkTitle = generalInfo?.name || '';
    const placemarkDescription: string = generalInfo?.description || '';
    return {
      balloonContent: `
        <div class='balloon_title'>${placemarkTitle}</div>
        <div class='balloon_address'>${address}</div>
        <div class='balloon_description'>${placemarkDescription}</div>
        <div class='balloon_description'>${getLocationPhone(false) as string}</div>
          `,
      hintContent: placemarkTitle,
      iconContent: placemarkTitle,
    };
  };

  const getPointOptions = () => {
    return {
      openEmptyHint: true,
      openEmptyBalloon: true,
      iconLayout: 'default#image',
    };
  };

  const resourceImage = () => {
    const { icon_url } = resource;
    const src = `${config.CDN_WORKER_IMG_URL}${icon_url || ''}`;
    return <UserPicStyled size={80} alt={getResourceName(resource)} src={src} />;
  };

  const serviceInfo = (): string | null | undefined => {
    let { confirmationAlert } = taxonomy;
    if (!confirmationAlert) {
      return null;
    }
    const selectedDate = moment.utc(start).format('HH,mm,DD/MM/YYYY').split(',');
    const clientShortId = client.shortId;
    const hour = selectedDate[0];
    const minutes = selectedDate[1];
    const appointmentDate = selectedDate[2];

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const customerPortalUrl = `${config.CUSTOMER_PORTAL_URL}${clientShortId}`;
    let joinTelemedLink = '';
    if (telemedLink) {
      joinTelemedLink = `
      <a target="_blank"
          style="display: block;"
          href=${telemedLink}>
          ${telemedLink}
      </a>`;
    }
    const shortCancellationLink = `
      <a target="_blank"
          style="display: block;"
          href=${customerPortalUrl}>
          ${customerPortalUrl}
      </a>`;
    const targets: KeyValue = {
      '[Hour]': hour,
      '[Minutes]': minutes,
      '[Date]': appointmentDate,
      '[BusinessName]': generalInfo?.shortName || generalInfo?.name || '',
      '[BusinessPhone]': getBusinessPhone(),
      '[ShortCancellationLink]': shortCancellationLink,
      '[JoinTelemedLink]': joinTelemedLink,
      '[StartTelemedLink]': '',
    };
    interface KeyValue {
      [key: string]: string;
    }
    const replaceAll = (params: KeyValue): string => {
      Object.keys(params).forEach((key) => {
        confirmationAlert = confirmationAlert?.split(key).join(params[key]) || '';
      });

      return confirmationAlert || '';
    };

    return renderHTML(replaceAll(targets));
  };

  const onCopyTelemedLink = () => {
    clearClipboardTimeout();
    copyToClipboard(telemedLink, {
      format: 'text/plain',
      onCopy: setClipboardTimeout,
    });
  };

  const getTelemedBlock = () => {
    return telemedLink ? (
      <TelemedContainer>
        {getMultilineTranslation(t('components.appointmentInfoBlock.telemedInfo'))}
        <div>
          <TelemedLink theme={theme} href={telemedLink} target="_blank">
            {t('components.appointmentInfoBlock.telemedJoinButton')}
          </TelemedLink>
          {clipboardTimerId ? (
            <Message onClick={clearClipboardTimeout}>
              <IconSuccess />
              {t('components.appointmentInfoBlock.telemedLinkInClipboard')}
            </Message>
          ) : (
            <Button theme={theme} variant="link" onClick={onCopyTelemedLink}>
              {t('components.appointmentInfoBlock.telemedCopyLink', { telemedLink })}
            </Button>
          )}
        </div>
      </TelemedContainer>
    ) : null;
  };

  const getLocationMap = () => {
    const mapState = {
      center,
      zoom,
      controls: ['zoomControl', 'fullscreenControl'],
    };
    return generalInfo?.address?.[0]?.latitude ? (
      <MapContainer>
        <YMaps>
          <Map
            defaultState={mapState}
            width="100%"
            modules={['control.ZoomControl', 'control.FullscreenControl']}
          >
            <Placemark
              modules={['geoObject.addon.balloon']}
              geometry={center}
              options={getPointOptions()}
              properties={getProperties()}
            />
          </Map>
        </YMaps>
      </MapContainer>
    ) : (
      <></>
    );
  };

  const telemedBackground = theme.telemedItemBackgroundColor || 'transparent';
  const renderContent = () => {
    const style = telemedLink ? { width: '100%', height: '100%' } : {};
    const content = (
      <>
        <Heading>{taxonomy.alias}</Heading>
        <Date>
          <span>
            {startDate} {address}
          </span>
          <EditBlockTop
            css="page"
            appointmentData={appointmentData}
            goHomeAfterCancel
          />
        </Date>
        <Resource>
          {resourceImage()}
          <div>{getResourceName(resource)}</div>
        </Resource>
        {serviceInfo()}
        {!telemedLink && getLocationPhone(true)}
        {getTelemedBlock()}
        {generalInfo?.showExtraFieldsInClientCabinet && (
            <AppointmentExtraFields
              appointmentExtraFields={appointmentExtraFields}
              onSubmitExtraFields={onSubmitExtraFields}
            />
          )}
        <EditBlockBottom
          css="page-bottom"
          appointmentData={appointmentData}
          goHomeAfterCancel
        />
      </>
    );
    return (
      <Content style={style} theme={theme}>
        {content}
      </Content>
    );
  };
  return (
    <>
      <ScrollBlock>
        {telemedLink && (
          <Helmet style={[{ cssText: `#root > * { background-color: ${telemedBackground}; }` }]} />
        )}
        {renderContent()}
        {!telemedLink && getLocationMap()}
      </ScrollBlock>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Content = styled('div')((props: { theme: ITheme }) => ({
  color: props.theme.textColor,
  width: '100%',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    [`[dir="ltr"] &`]: {
      padding: `0 ${unit}px ${unit * 5}px ${unit * 22.2}px`,
    },
    [`[dir="rtl"] &`]: {
      padding: `0 ${unit * 22.2}px ${unit * 5}px ${unit}px`,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${unit * 2}px ${unit * 2}px`,
    '& > *': {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
}));

const Heading = styled('h2')(largeText, {
  marginBottom: unit * 2,
  order: -2,
});

const Date = styled('div')(mediumText, {
  marginBottom: unit * 4,
  span: {
    display: 'inline-block',
    [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
      [`[dir="ltr"] &`]: {
        marginRight: unit * 4,
      },
      [`[dir="rtl"] &`]: {
        marginLeft: unit * 4,
      },
    },
  },
});

const Resource = styled('div')(mediumText, {
  position: 'relative',
  marginBottom: unit * 4,
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    order: -1,
    marginBottom: unit * 3,
    '& > *': {
      margin: 'auto',
    },
  },
});

const Location = styled('div')(smallText, (props: { theme: ITheme }) => ({
  fontWeight: 500,
  a: {
    color: props.theme.mainColor,
    [`[dir] &`]: {
      display: 'inline-block',
      direction: 'ltr',
    },
  },
  '*:not(:last-child)': {
    [`[dir="ltr"] &`]: {
      marginRight: unit * 0.5,
    },
    [`[dir="rtl"] &`]: {
      marginLeft: unit * 0.5,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    textAlign: 'left',
  },
}));

const UserPicStyled = styled(UserPic)({
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [`[dir="ltr"] &`]: {
      left: `-${unit * 10}px`,
    },
    [`[dir="rtl"] &`]: {
      right: `-${unit * 10}px`,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    margin: `0 auto ${unit * 2}px`,
  },
});

const EditBlockTop = styled(AppointmentEditBlock)(hideForMobile);

const EditBlockBottom = styled(AppointmentEditBlock)(showForMobile, {
  marginTop: unit * 4,
});

const MapContainer = styled('div')({
  flexGrow: 1,
  flexShrink: 1,
  width: '100%',
  height: '100%',
  '& > *': {
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
});

const TelemedContainer = styled('div')({
  padding: `${unit * 2.4}px 0`,
  [`[dir="ltr"] &`]: {
    textAlign: 'left',
  },
  [`[dir="rtl"] &`]: {
    textAlign: 'right',
  },
  div: {
    paddingTop: unit * 8,
    '& > *': {
      marginBottom: unit * 2,
    },
    [`@media screen and (max-width: ${breakpointLarge}px)`]: {
      paddingTop: unit * 2,
      textAlign: 'center',
      button: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
});

const TelemedLink = styled(Button.withComponent('a'))((props: { theme: ITheme }) => ({
  display: 'inline-flex',
  background: props.theme.passiveColor,
  color: props.theme.textColor ? darken(0.1, props.theme.textColor) : '#000',
  textDecoration: 'none',
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    textAlign: 'center',
  },
}));

const Message = styled('span')(smallText, {
  display: 'flex',
  cursor: 'pointer',
  svg: {
    width: unit * 1.8,
    height: unit * 1.8,
    [`[dir="ltr"] &`]: {
      marginRight: unit,
    },
    [`[dir="rtl"] &`]: {
      marginLeft: unit,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    justifyContent: 'center',
  },
});
